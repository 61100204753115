// include first and last
export function sampleDataset(data, maxSize) {
  if (!data || data.length <= maxSize) {
    return data;
  }
  const sampled = [data[0]];
  for (let i = 1; i < data.length - 1; i += data.length / maxSize) {
    sampled.push(data[Math.floor(i)]);
  }
  sampled.push(data[data.length - 1]);

  return sampled;
}

/**
 * Finds the edges of groups of data, allows repeat groups:
 * e.g. TOR, SAS, TOR
 * returns [[key, min, max, sortedIndex]] e.g. [2019, 0, 100] or ['TOR', 300, 450]
 */
export function sortedExtentsBy(data, groupKey, valueKey, gapless) {
  if (!data || !data.length) {
    return [];
  }

  let currGroupKey;
  let currGroup;
  const extents = [];
  for (let i = 0; i < data.length; ++i) {
    const d = data[i];
    // starting a new group
    if (d[groupKey] !== currGroupKey) {
      currGroupKey = d[groupKey];
      currGroup = [d[groupKey], d[valueKey], d[valueKey], extents.length];
      extents.push(currGroup);
    }

    // update the max value
    currGroup[2] = d[valueKey];
  }

  // if we don't want gaps, max of i = min of i + 1
  if (gapless) {
    for (let i = 0; i < extents.length - 1; ++i) {
      extents[i][2] = extents[i + 1][1];
    }
  }

  return extents;
}
