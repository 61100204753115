import styled from "styled-components/macro";
import formatDate from "date-fns/format";
import React from "react";
import TooltipTrigger from "react-popper-tooltip";
import { useQueryParam, NumberParam } from "use-query-params";

import { Query } from "react-apollo";
import gql from "graphql-tag";
import AutoSizer from "react-virtualized-auto-sizer";
import Helmet from "react-helmet";
import { IconClose } from "../components/icons";
import { max, min } from "d3-array";

import PlayerTimeSeries from "../components/PlayerTimeSeries";
import PlayerShotDistanceChart from "../components/charts/PlayerShotDistanceChart";
import ShootingSignature from "../components/charts/ShootingSignature";
import { ShotDistanceProvider } from "../components/charts/contexts";
import PlayerStackedShotBins, {
  PlayerStackedShotBinsLegend,
} from "../components/PlayerStackedShotBins";
import VisibilitySensor from "react-visibility-sensor";
import Layout from "../components/Layout";
import ShotPtsPanelHeader from "../components/ShotPtsPanelHeader";

import {
  Box,
  Grid,
  NumberText,
  InlineBox,
  Heading,
  Text,
  Flex,
  FlexText,
  Container,
  IconButton,
  Link,
  Card,
  Button,
  CardHelpText,
  CardDescription,
  CardContainer,
  InlineText,
  InlineNumberText,
  InfoTooltip,
  Select,
  BoundContainer,
} from "../components/core";
import {
  minBaseline,
  movingAverageWindow,
  movingAverageWindowBins,
  bins,
  longTermFga,
  shortTermFga,
} from "../config";
import PlayerShootingSummaryTable from "../components/PlayerShootingSummaryTable";
import { seasonFormat, pctFormat0Symbol, deltaPctFormat0 } from "../format";
import ExpandControl from "../components/ExpandControl";
import LoadingSpinner from "../components/LoadingSpinner";
import { useLocation } from "react-router-dom";
import { colorScales } from "../scales";

const PLAYER_DETAILS_QUERY = gql`
  query PlayerDetailsQuery(
    $playerId: Int!
    $shotInterval: Int
    $binShotInterval: Int
  ) {
    players(ids: [$playerId]) {
      id
      name
      team {
        id
        full_name
        abbr
      }
      derivedPlayerStats {
        last_fg2a_date
        fg2_baseline_n
        fg2_pct_baseline
        fg2_pct_mavg
        fg2_pct_mavg_short
        fg2_pct_mavg_back_short_term
        fg2_pct_mavg_back_long_term
        fg2_pct_mavg_back_season
        fg2_pct_season
        fg2_pct_back_season
        last_fg3a_date
        fg3_baseline_n
        fg3_pct_baseline
        fg3_pct_mavg
        fg3_pct_mavg_short
        fg3_pct_mavg_back_short_term
        fg3_pct_mavg_back_long_term
        fg3_pct_mavg_back_season
        fg2_pct_mavg_n
        fg2_pct_mavg_back_short_term_n
        fg2_pct_mavg_back_long_term_n
        fg2_pct_mavg_back_season_n
        fg3_pct_mavg_n
        fg3_pct_mavg_back_short_term_n
        fg3_pct_mavg_back_long_term_n
        fg3_pct_mavg_back_season_n
        fg3_pct_season
        fg3_pct_back_season
      }
      derivedPlayerShotStats(shotInterval: $shotInterval, shotPtsSplit: true) {
        player_id
        season_id
        shot_id
        shot_pts
        team_abbr
        player_shot_index
        fg_pct_mavg
      }
      binShots: derivedPlayerShotStats(
        shotInterval: $binShotInterval
        shotPtsSplit: false
      ) {
        player_id
        season_id
        shot_id
        team_abbr
        shot_pts
        player_shot_index_overall
        shot_bin_1_pct_mavg
        shot_bin_2_pct_mavg
        shot_bin_3_pct_mavg
        shot_bin_4_pct_mavg
        shot_bin_5_pct_mavg
      }
      derivedPlayerShotDistanceStats {
        season_id
        shot_distance
        pps_smoothed
        fgm_smoothed
        fga_smoothed
        fg_pct_smoothed
        dist_pct_smoothed
        total_fga
        avg_fga_smoothed
      }
    }
    teammates(playerId: $playerId) {
      id
      name
      season_id
    }
    leagueStats {
      derivedLeagueShotDistanceStats {
        season_id
        shot_distance
        pps_smoothed
        fg_pct_smoothed
        dist_pct_smoothed
      }
    }
  }
`;

export default function PlayerPage({ match, ...other }) {
  const playerId = +match.params.playerId;
  const shotInterval = 10;
  const binShotInterval = 50;

  const prevData = React.useRef(null);

  return (
    <Container>
      <Query
        query={PLAYER_DETAILS_QUERY}
        variables={{ playerId, shotInterval, binShotInterval }}
      >
        {({ loading, error, data }) => {
          if (loading && !prevData.current) {
            return (
              <Layout>
                <Helmet>
                  <title>Shotline: NBA Shooting Vis</title>
                </Helmet>
                <Flex justifyContent="center" alignItems="center" height={100}>
                  <LoadingSpinner />
                </Flex>
              </Layout>
            );
          }
          if (error) {
            return <Box>Error</Box>;
          }

          let player;
          let teammates;
          let leagueStats;
          if (!data) {
            player = prevData.current.player;
            teammates = prevData.current.teammates;
            leagueStats = prevData.current.leagueStats;
          } else {
            player = data.players[0];
            teammates = data.teammates;
            leagueStats = data.leagueStats;
            prevData.current = data;
          }

          if (process.env.NODE_ENV === "development") {
            console.log("[query] PlayerPage", data);
          }

          return (
            <PlayerPageInner
              loading={loading}
              player={player}
              teammates={teammates}
              leagueStats={leagueStats}
            />
          );
        }}
      </Query>
    </Container>
  );
}

const FadeFlex = styled(Flex)`
  transition: 0.2s opacity;
  opacity: ${(props) => (props.active ? 1 : 0)};
`;

const PlayerNavHeader = ({ player, active, teammates }) => (
  <FadeFlex alignItems="center" active={active}>
    <Heading as="h6" fontSize={2} mr={4} lineHeight={1}>
      {player.name}
    </Heading>
    <Heading as="h6" fontSize={1} lineHeight={1}>
      <Link to={`/?t=${player.team.id}`}>{player.team.abbr}</Link>
    </Heading>
    <Text fontSize={1}>
      <RosterPopover player={player} teammates={teammates} />
    </Text>
  </FadeFlex>
);

const FgPctChartPanel = ({ player, shotPts }) => (
  <Card p={[4, 5]} pr={[0, 0]} flex="1 1 900px">
    <ShotPtsPanelHeader
      shotPts={shotPts}
      alignItems="flex-start"
      description={`${player.name}'s average ${shotPts}P% over ${movingAverageWindow} shot windows across his career`}
    >
      <Flex>
        <span>{shotPts}P% Moving Average</span>
        <InfoTooltip>
          This chart shows a moving average of the last {movingAverageWindow}{" "}
          shots over time. <InlineText color="teal.5">Teal</InlineText> and
          above the dashed line are higher than their baseline (or hovered
          point), while <InlineText color="pink.5">pink</InlineText> and below
          mean the opposite. Their career baseline is computed as the average
          over the first season or the first {minBaseline} shots, whichever
          comes later
          {player.derivedPlayerStats[`fg${shotPts}_baseline_n`]
            ? ` (${pctFormat0Symbol(
                player.derivedPlayerStats[`fg${shotPts}_pct_baseline`]
              )} on ${player.derivedPlayerStats[
                `fg${shotPts}_baseline_n`
              ].toLocaleString()} shots for ${player.name}).`
            : "."}{" "}
          Before their baseline has been computed, the line is thin and gray.
        </InfoTooltip>
      </Flex>
    </ShotPtsPanelHeader>

    <AutoSizer disableHeight>
      {({ width }) => (
        <PlayerTimeSeries
          shotPts={shotPts}
          minBaseline={minBaseline}
          player={player}
          width={width}
          height={300}
        />
      )}
    </AutoSizer>
  </Card>
);

const ShootingSignaturePanel = ({
  player,
  activeSeasonId,
  onChangeActiveSeason,
}) => {
  return (
    <Box
      p={0}
      pr={0}
      mt={-3}
      mb={3}
      mr={[3, 5]}
      flex="1 1 500px"
      maxWidth="700px"
    >
      <AutoSizer disableHeight>
        {({ width }) => (
          <ShootingSignature
            player={player}
            activeSeasonId={activeSeasonId}
            width={width}
            height={120}
          />
        )}
      </AutoSizer>
      <Flex textAlign="center" mt={3} mb={2}>
        <Box
          bg="gray.1"
          color="gray.7"
          mx="auto"
          borderRadius={20}
          fontSize={1}
          p={2}
          px={4}
        >
          <SeasonLinks player={player} activeSeasonId={activeSeasonId} />
        </Box>
      </Flex>
    </Box>
  );
};

const ShotDistanceChartPanel = ({
  player,
  type,
  leagueStats,
  activeSeasonId,
  onChangeActiveSeason,
}) => (
  <Card p={[4, 5]} pr={[0, 0]} flex="1 1 500px">
    <Flex alignItems="flex-start" justifyContent="space-between" pr={[4, 5]}>
      <Heading as="h3" fontSize={2} color={"gray.7"} mb={0}>
        <InlineBox>
          {type === "dist_pct"
            ? "Shot Proportion by Distance"
            : "FG% by Distance"}
        </InlineBox>
        <InfoTooltip>
          Note that some smoothing is applied to the data, so actual FGM and FGA
          from a given distance will vary from the raw data.
        </InfoTooltip>
      </Heading>

      <Text fontSize={1} mt={[0, -2]} mr={[0, -2]} ml={[2, 0]}>
        <SeasonSelector
          player={player}
          activeSeasonId={activeSeasonId}
          onChange={onChangeActiveSeason}
        />
      </Text>
    </Flex>
    <CardDescription pr={[4, 5]}>
      The season average of{" "}
      <InlineText color="primary" fontWeight="bold">
        {player.name}
      </InlineText>{" "}
      vs.{" "}
      <InlineText color="gray.7" fontWeight="bold">
        the whole NBA
      </InlineText>
    </CardDescription>

    <AutoSizer disableHeight>
      {({ width }) => (
        <PlayerShotDistanceChart
          type={type}
          player={player}
          leagueStats={leagueStats}
          activeSeasonId={activeSeasonId}
          width={width}
          height={250}
        />
      )}
    </AutoSizer>
  </Card>
);

const TooltipDiv = styled.div`
  z-index: 10000;
`;

const Tooltip = (player, teammates, onClose, search) => ({
  tooltipRef,
  getTooltipProps,
}) => (
  <TooltipDiv
    {...getTooltipProps({
      ref: tooltipRef,
    })}
  >
    <Card p={null} px={5} py={4} mt={2}>
      <Flex>
        <Box flex="1">
          <Heading
            as="h5"
            flex="1"
            lineHeight={1}
            fontSize={1}
            m={0}
            color={"gray.7"}
            alignItems="flex-end"
          >
            {player.team.full_name}
            <InlineText
              ml={1}
              flex="1"
              fontSize={0}
              color={"gray.7"}
              fontWeight={400}
            >
              {seasonFormat(teammates[0].season_id)}
            </InlineText>
          </Heading>
        </Box>
        <IconButton
          key={"close"}
          variant="basic-light"
          circle
          fontSize={2}
          ml={2}
          mr={-3}
          mt={-1}
          onClick={onClose}
          aria-label="Close team dialog"
        >
          <IconClose />
        </IconButton>
      </Flex>
      <Box>
        {teammates.map((teammate) => (
          <Text
            key={teammate.id}
            fontWeight={teammate.id === player.id ? "bold" : undefined}
          >
            <Link
              key={teammate.id}
              to={`/p/${teammate.id}${search}`}
              onClick={onClose}
            >
              {teammate.name}
            </Link>
          </Text>
        ))}
      </Box>
    </Card>
  </TooltipDiv>
);

const RosterPopover = ({ player, teammates }) => {
  const [expanded, setExpanded] = React.useState(false);
  const { search } = useLocation();
  return (
    <TooltipTrigger
      placement="bottom"
      trigger="click"
      tooltip={Tooltip(player, teammates, () => setExpanded(false), search)}
      tooltipShown={expanded}
      onVisibilityChange={setExpanded}
    >
      {({ getTriggerProps, triggerRef }) => (
        <span
          {...getTriggerProps({
            ref: triggerRef,
            className: "trigger",
          })}
        >
          <ExpandControl
            aria-label={expanded ? "Show teammates" : "Hide teammates"}
            ml={2}
            circle
            variant="gray"
            expanded={expanded}
          />
        </span>
      )}
    </TooltipTrigger>
  );
};

const PlayerHeader = ({ player, teammates }) => {
  return (
    <Box
      my={5}
      style={{
        position: "relative",
        zIndex: 2 /* must be above shooting signature */,
      }}
    >
      <Heading
        as="h1"
        fontSize={4}
        textAlign="center"
        mt={5}
        justifyContent="center"
      >
        {player.name}
      </Heading>
      <Heading
        as="h2"
        fontSize={2}
        textAlign="center"
        mb={5}
        justifyContent="center"
      >
        <Link to={`/?t=${player.team.id}`}>{player.team.full_name}</Link>
        <RosterPopover player={player} teammates={teammates} />
      </Heading>
    </Box>
  );
};

const ShotBinChartPanel = ({ player }) => {
  const latestStats = player.binShots[player.binShots.length - 1];
  const latestBins = bins
    .map(
      (bin, i) => `
  ${i === bins.length - 1 ? " and " : ""}${pctFormat0Symbol(
        latestStats[`shot_bin_${i + 1}_pct_mavg`]
      )} from ${bin}`
    )
    .join(", ");

  return (
    <Card>
      <Heading as="h3" flex="1" fontSize={2} color={"gray.7"}>
        Shooting Distance Proportions
        <InfoTooltip>
          <p>
            Each shot is placed into a bin based on how far from the basket it
            was taken from. The proportions of these bins is computed using a{" "}
            {movingAverageWindowBins} shot moving window.
          </p>
          <div>The latest values show {latestBins}.</div>
        </InfoTooltip>
      </Heading>
      <CardDescription>
        {player.name}'s average shot distribution by distance from the basket
        over {movingAverageWindowBins} shot windows
      </CardDescription>
      <PlayerStackedShotBinsLegend mb={3} />
      <AutoSizer disableHeight>
        {({ width }) => (
          <PlayerStackedShotBins player={player} width={width} height={400} />
        )}
      </AutoSizer>
    </Card>
  );
};

function getAvailableSeasons(player) {
  // TODO: this should be more general
  const availableSeasons = player.derivedPlayerShotDistanceStats
    .map((d) => d.season_id)
    .filter((d, i, a) => a.indexOf(d) === i);

  return availableSeasons;
}

const SeasonLinks = ({ player, activeSeasonId, ...other }) => {
  const availableSeasons = getAvailableSeasons(player).slice().reverse();
  const location = useLocation();

  return (
    <FlexText
      {...other}
      flexWrap="wrap"
      alignItems="center"
      justifyContent="center"
    >
      {availableSeasons.map((d) => {
        return (
          <Link
            key={d}
            to={`${location.pathname}?season=${d}`}
            mx={2}
            my={1}
            width={65}
            replace={true}
          >
            <NumberText
              fontWeight={d === activeSeasonId ? "bold" : "normal"}
              textAlign="center"
            >
              {seasonFormat(d)}
            </NumberText>
          </Link>
        );
      })}
    </FlexText>
  );
};

const SeasonSelector = ({ player, activeSeasonId, onChange, ...other }) => {
  const availableSeasons = getAvailableSeasons(player);
  const handleChange = (evt) => {
    onChange(+evt.target.value);
  };

  return (
    <Select value={activeSeasonId} onChange={handleChange} {...other}>
      {availableSeasons.map((d) => {
        return (
          <option key={d} value={d} selected={d === activeSeasonId}>
            {seasonFormat(d)}
          </option>
        );
      })}
    </Select>
  );
};

const SummaryMetric = ({
  value,
  label,
  base,
  color,
  format = pctFormat0Symbol,
  formatDelta = deltaPctFormat0,
}) => {
  return (
    <div>
      <NumberText fontSize={5} fontWeight={700} lineHeight={1} color={color}>
        {value == null
          ? "\u00A0" //nbsp
          : format(value)}
        {base == null ? null : (
          <InlineNumberText
            ml={1}
            fontWeight={700}
            fontSize={1}
            style={{ color: colorScales.delta(value - base) }}
          >
            {formatDelta(value - base)}
          </InlineNumberText>
        )}
      </NumberText>
      <Text fontSize={1} color="gray.6">
        {label}
      </Text>
    </div>
  );
};

const SummaryMetrics = ({ player, maxSeason }) => {
  const stats = player.derivedPlayerStats;
  return (
    <Flex flexWrap="wrap" width="100%" justifyContent="center">
      <Box mb={2}>
        <Flex alignItems="center" mb={1}>
          <Box
            style={{ height: "1px", opacity: 0.3 }}
            bg="2ptFg"
            width="100%"
            mr={1}
            ml={0}
          />
          <Text
            color="2ptFg"
            fontWeight={700}
            fontSize={1}
            style={{ whiteSpace: "nowrap" }}
          >
            2P%{" "}
            {player.derivedPlayerStats[`last_fg2a_date`] == null ? null : (
              <InlineText
                fontSize={0}
                color="2ptFg"
                fontWeight={400}
                pl={1}
                style={{ opacity: 0.75 }}
              >
                Last 2PA on{" "}
                {formatDate(
                  new Date(
                    `${player.derivedPlayerStats[`last_fg2a_date`]}T00:00:00`
                  ),
                  "PP"
                )}
              </InlineText>
            )}
          </Text>
          <Box
            style={{ height: "1px", opacity: 0.3 }}
            bg="2ptFg"
            width="100%"
            ml={1}
            mr={5}
          />
        </Flex>
        <Grid
          gridGap="4"
          gridTemplateColumns={[
            `repeat(2, 1fr)`,
            `repeat(4, minmax(100px, 135px))`,
          ]}
          justifyContent="center"
          my={2}
          mx={2}
        >
          <SummaryMetric
            value={stats.fg2_pct_mavg_short}
            label={`Last ${shortTermFga} shots`}
            base={stats.fg2_pct_mavg_back_short_term}
          />
          <SummaryMetric
            value={stats.fg2_pct_mavg}
            label={`Last ${longTermFga} Shots`}
            base={stats.fg2_pct_mavg_back_long_term}
          />
          <SummaryMetric
            value={stats.fg2_pct_season}
            label={`${seasonFormat(maxSeason)} Season`}
            base={stats.fg2_pct_back_season}
          />
          <SummaryMetric
            value={stats.fg2_pct_baseline}
            label={`Baseline`}
            color="gray.6"
          />
        </Grid>
      </Box>
      <Box mb={2}>
        <Flex alignItems="center" mb={1}>
          <Box
            style={{ height: "1px", opacity: 0.3 }}
            bg="3ptFg"
            width="100%"
            ml={0}
            mr={1}
          />
          <Text
            color="3ptFg"
            fontWeight={700}
            fontSize={1}
            style={{ whiteSpace: "nowrap" }}
          >
            3P%{" "}
            {player.derivedPlayerStats[`last_fg3a_date`] == null ? null : (
              <InlineText
                fontSize={0}
                color="3ptFg"
                fontWeight={400}
                pl={1}
                style={{ opacity: 0.75 }}
              >
                Last 3PA on{" "}
                {formatDate(
                  new Date(
                    `${player.derivedPlayerStats[`last_fg3a_date`]}T00:00:00`
                  ),
                  "PP"
                )}
              </InlineText>
            )}
          </Text>

          <Box
            style={{ height: "1px", opacity: 0.3 }}
            bg="3ptFg"
            width="100%"
            ml={1}
            mr={5}
          />
        </Flex>
        <Grid
          gridGap="4"
          gridTemplateColumns={[
            `repeat(2, 1fr)`,
            `repeat(4, minmax(100px, 135px))`,
          ]}
          justifyContent="center"
          my={2}
          mx={2}
        >
          <SummaryMetric
            value={stats.fg3_pct_mavg_short}
            label={`Last ${shortTermFga} shots`}
            base={stats.fg3_pct_mavg_back_short_term}
          />
          <SummaryMetric
            value={stats.fg3_pct_mavg}
            label={`Last ${longTermFga} Shots`}
            base={stats.fg3_pct_mavg_back_long_term}
          />
          <SummaryMetric
            value={stats.fg3_pct_season}
            label={`${seasonFormat(maxSeason)} Season`}
            base={stats.fg3_pct_back_season}
          />
          <SummaryMetric
            value={stats.fg3_pct_baseline}
            label={`Baseline`}
            color="gray.6"
          />
        </Grid>
      </Box>
    </Flex>
  );
};

const PlayerPageInner = ({ loading, player, teammates, leagueStats }) => {
  const seasons = getAvailableSeasons(player);
  const maxSeason = max(seasons);
  const minSeason = min(seasons);
  const onChangeHeaderVisibility = (visible) => setShowNavHeader(!visible);
  const [showNavHeader, setShowNavHeader] = React.useState(false);
  const [stateActiveSeasonId = maxSeason, setActiveSeasonId] = useQueryParam(
    "season",
    NumberParam
  );
  const activeSeasonId = Math.max(
    minSeason,
    Math.min(maxSeason, stateActiveSeasonId)
  );
  const handleChangeSeason = (newSeasonId) => {
    setActiveSeasonId(newSeasonId, "replaceIn");
  };

  return (
    <Layout
      navHeader={
        <PlayerNavHeader
          player={player}
          teammates={teammates}
          active={showNavHeader}
        />
      }
    >
      <Helmet>
        <title>{player.name} - Shotline: NBA Shooting Vis</title>
      </Helmet>
      <Box opacity={loading ? 0.5 : 1}>
        <VisibilitySensor onChange={onChangeHeaderVisibility}>
          <PlayerHeader player={player} teammates={teammates} />
        </VisibilitySensor>
        <CardContainer>
          <BoundContainer>
            <ShotDistanceProvider>
              <Flex justifyContent="center" flexWrap="wrap">
                <ShootingSignaturePanel
                  player={player}
                  activeSeasonId={activeSeasonId}
                  onChangeActiveSeason={handleChangeSeason}
                />
              </Flex>
            </ShotDistanceProvider>
            <Flex justifyContent="center" flexWrap="wrap" my={4}>
              <SummaryMetrics player={player} maxSeason={maxSeason} />
            </Flex>

            <ShotDistanceProvider>
              <Flex justifyContent="center" flexWrap="wrap">
                <ShotDistanceChartPanel
                  type="dist_pct"
                  player={player}
                  leagueStats={leagueStats}
                  activeSeasonId={activeSeasonId}
                  onChangeActiveSeason={handleChangeSeason}
                />
                <ShotDistanceChartPanel
                  type="fg_pct"
                  player={player}
                  leagueStats={leagueStats}
                  activeSeasonId={activeSeasonId}
                  onChangeActiveSeason={handleChangeSeason}
                />
              </Flex>
            </ShotDistanceProvider>
            <Flex justifyContent="center" flexWrap="wrap">
              <FgPctChartPanel shotPts={2} player={player} />
              <FgPctChartPanel shotPts={3} player={player} />
            </Flex>
            <ShotBinChartPanel player={player} />
          </BoundContainer>
        </CardContainer>
      </Box>
    </Layout>
  );
};
