import React from "react";
import styled, { css } from "styled-components/macro";
import { parse, stringify } from "query-string";
import Downshift from "downshift";
import { Box, Text, Input, InlineText, IconButton } from "./core";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { useLocation, withRouter } from "react-router-dom";
import key from "keymaster";
import { IconClose, IconSearch } from "./icons";
import { useLatestSeasonId } from "../app-context";

// teams in the given season, but all players
const SEARCH_ITEMS_QUERY = gql`
  query SearchItems($season: Int!) {
    teams(season: $season) {
      id
      full_name
      name
      location
      abbr
    }

    players {
      id
      name
      first_name
      last_name
      team {
        id
        abbr
      }
      derivedPlayerStats {
        last_fg2a_date
        last_fg3a_date
        fg2_pct_mavg_n
        fg3_pct_mavg_n
      }
    }
  }
`;
function transformSearchItems(allPlayers, allTeams) {
  const transformedPlayers = allPlayers.map((d) => {
    const lastDate =
      d.derivedPlayerStats.last_fg2a_date > d.derivedPlayerStats.last_fg3a_date
        ? d.derivedPlayerStats.last_fg2a_date
        : d.derivedPlayerStats.last_fg3a_date;

    // no shots means not interesting
    const lastShotYear = lastDate ? +lastDate.substring(0, 4) : -1;

    return {
      type: "player",
      value: d.id,
      label: d.name,
      team: d.team.abbr,
      searchValue: d.name.replace(/[^\w\s]/g, ""),
      lastDate,
      lastShotYear,
      totalFga:
        d.derivedPlayerStats.fg2_pct_mavg_n +
          d.derivedPlayerStats.fg3_pct_mavg_n || 0,
    };
  });

  // sort by number of shots for active players, otherwise active players elevated to top
  transformedPlayers.sort((a, b) => {
    if (b.lastShotYear === a.lastShotYear) {
      return b.totalFga - a.totalFga;
    }
    return b.lastShotYear - a.lastShotYear;
  });

  const allItems = [
    ...transformedPlayers,
    ...allTeams.map((d) => ({
      type: "team",
      value: d.id,
      label: d.full_name,
      searchValue: d.full_name.replace(/[^\w\s]/g, ""),
    })),
  ];

  return allItems;
}

const RightIconContainer = styled(Box)`
  position: absolute;
  right: ${(props) => props.theme.space[3]}px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
`;

const SearchItemBox = styled(Box)`
  background: ${(props) => props.theme.colors.white};
  ${(props) =>
    props.isHighlighted &&
    css`
      background-color: ${props.theme.colors.gray[2]};
    `}
`;

const SearchItemsBox = styled(Box)`
  position: absolute;
  max-height: 300px;
  overflow: auto;
  width: 100%;
  z-index: 1000;
  box-shadow: ${(props) => props.theme.shadows.small};
`;

const SearchItem = ({ item, index, ...other }) => {
  return (
    <SearchItemBox py={2} px={4} {...other}>
      <InlineText fontWeight="700" fontSize={1} mr={2}>
        {item.label}
      </InlineText>
      <InlineText color="gray.5" fontSize={0}>
        {item.type === "player" && item.team}
        {item.type === "team" && "Team"}
      </InlineText>
    </SearchItemBox>
  );
};

const SearchItems = React.forwardRef(
  ({ item, index, children, ...other }, ref) => {
    return (
      <SearchItemsBox ref={ref} {...other}>
        {children}
      </SearchItemsBox>
    );
  }
);

function stateReducer(state, changes) {
  switch (changes.type) {
    // @pbeshai modification: auto select first item when typing so we
    // can just press enter to select it.
    case Downshift.stateChangeTypes.changeInput:
      // note we set highlightIndex to 0 here so we always have the
      // top match selected even if there was a previously selected one
      // (e.g. the user moused over item 5 before typing). If we do not
      // it is possible that the existing index is no longer available
      // due to the list being filtered and so we'd see nothing highlighted.
      if (changes.inputValue !== "") {
        return {
          ...changes,
          highlightedIndex: 0,
        };
      }
      return changes;

    default:
      return changes;
  }
}

const OmniSearch = ({
  onChange,
  initialSelectedItem,
  searchItems,
  ...other
}) => {
  const inputRef = React.useRef(null);

  // bind "/" keyboard shortcut to focus the input field
  React.useEffect(() => {
    key("/", () => {
      if (inputRef.current) {
        inputRef.current.focus();
        return false; // prevent propagation
      }
    });
    return () => {
      key.unbind("/");
    };
  }, []);
  return (
    <>
      <Text style={{ display: "none" }} id="omnisearch-label">
        Search for players or teams
      </Text>
      <Downshift
        role="search"
        id="omnisearch"
        onChange={onChange}
        initialSelectedItem={initialSelectedItem}
        itemToString={(item) => (item ? item.label : "")}
        stateReducer={stateReducer}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          getRootProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          clearSelection,
        }) => {
          let filteredItems;
          if (isOpen && inputValue) {
            const matchRegex = new RegExp(
              `(?:^|\\b)${inputValue
                .replace(/[^\w\s]/g, "") // remove non word characters
                .split(" ")
                .map((d) => `${d}.*`)
                .join(" ")}`,
              "i"
            );
            filteredItems = searchItems.filter((d) =>
              matchRegex.test(d.searchValue)
            );
          } else {
            filteredItems = [];
          }
          return (
            <Box
              css={css`
                position: relative;
              `}
              width={250}
              {...getRootProps()}
            >
              <Input
                placeholder={"Search players or teams"}
                ref={inputRef}
                {...getInputProps()}
                bg="gray.0"
              />
              <RightIconContainer>
                {inputValue ? (
                  <IconButton
                    key={"close"}
                    variant="input-btn"
                    aria-label="Clear search"
                    onClick={clearSelection}
                  >
                    <IconClose />
                  </IconButton>
                ) : (
                  <Text
                    key={"search"}
                    fontSize={3}
                    color="gray.5"
                    pr={1}
                    opacity={[0, 1]}
                  >
                    <IconSearch />
                  </Text>
                )}
              </RightIconContainer>
              <SearchItems {...getMenuProps()}>
                {isOpen
                  ? filteredItems.slice(0, 20).map((item, index) => (
                      <SearchItem
                        item={item}
                        index={index}
                        isHighlighted={highlightedIndex === index}
                        isSelected={selectedItem === item}
                        {...getItemProps({
                          key: `${item.type}-${item.value}`,
                          index,
                          item,
                        })}
                      />
                    ))
                  : null}
              </SearchItems>
            </Box>
          );
        }}
      </Downshift>
    </>
  );
};

const OmniSearchWithQuery = ({ history, location }) => {
  const latestSeasonId = useLatestSeasonId();
  const { search } = useLocation();
  window.his = history;

  const handleChange = (selectedItem) => {
    if (!selectedItem) {
    } else if (selectedItem.type === "player") {
      const playerId = selectedItem.value;
      history.push(`/p/${playerId}${search}`);
    } else if (selectedItem.type === "team") {
      const teamId = selectedItem.value;
      const params = parse(search);
      params.t = teamId;
      if (params.season) delete params.season;

      history.push(`/?${stringify(params)}`);
    }
  };
  return (
    <Query query={SEARCH_ITEMS_QUERY} variables={{ season: latestSeasonId }}>
      {({ loading, error, data }) => {
        if (error) {
          return <Box>Error</Box>;
        }

        const searchItems = loading
          ? []
          : transformSearchItems(data.players, data.teams);
        return (
          <OmniSearch
            key={location.key}
            onChange={handleChange}
            searchItems={searchItems}
          />
        );
      }}
    </Query>
  );
};

export default React.memo(withRouter(OmniSearchWithQuery));
