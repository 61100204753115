import "styled-components/macro";

import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Link as RouterLink } from "react-router-dom";
import Helmet from "react-helmet";
import PlayerStatPanel from "../components/PlayerStatPanel";
import { useQueryParam, BooleanParam, NumberParam } from "use-query-params";
import {
  Box,
  Heading,
  Flex,
  Grid,
  Container,
  IconButton,
  InlineText,
  CardContainer,
} from "../components/core";
import { IconClose } from "../components/icons";
import Layout from "../components/Layout";

import { seasonFormat, pctFormat } from "../format";
import LoadingSpinner from "../components/LoadingSpinner";
import { useLatestSeasonId } from "../app-context";
import TopPlayers from "../components/TopPlayers";

const deltaTypes = ["short_term", "long_term", "season", "baseline"];
const ptTypes = [2, 3];

const ALL_TEAMS_AND_PLAYERS = gql`
  query AllTeamsAndPlayers($season: Int!) {
    teams(season: $season) {
      id
      full_name
      name
      location
      abbr
    }
    players(season: $season) {
      id
      name
      first_name
      last_name
      team {
        id
        abbr
      }
      derivedPlayerStats {
        last_fg2a_date
        fg2_baseline_n
        fg2_pct_baseline
        fg2_pct_mavg
        fg2_pct_mavg_short
        fg2_pct_mavg_back_short_term
        fg2_pct_mavg_back_long_term
        fg2_pct_mavg_back_season
        fg2_pct_season
        fg2_pct_back_season
        fg2_pct_season_n
        fg2_pct_back_season_n
        last_fg3a_date
        fg3_baseline_n
        fg3_pct_baseline
        fg3_pct_mavg
        fg3_pct_mavg_short
        fg3_pct_mavg_back_short_term
        fg3_pct_mavg_back_long_term
        fg3_pct_mavg_back_season
        fg2_pct_mavg_n
        fg2_pct_mavg_back_short_term_n
        fg2_pct_mavg_back_long_term_n
        fg2_pct_mavg_back_season_n
        fg2_pct_mavg_rank
        fg2_pct_mavg_delta_baseline_rank
        fg2_pct_mavg_delta_short_term_rank
        fg2_pct_mavg_delta_long_term_rank
        fg2_pct_mavg_delta_season_rank
        fg2_pct_mavg_delta_baseline
        fg2_pct_mavg_delta_short_term
        fg2_pct_mavg_delta_long_term
        fg2_pct_mavg_delta_season
        fg2_pct_delta_season
        fg2_pct_delta_season_rank

        fg3_pct_season
        fg3_pct_back_season
        fg3_pct_season_n
        fg3_pct_back_season_n
        fg3_pct_mavg_n
        fg3_pct_mavg_back_short_term_n
        fg3_pct_mavg_back_long_term_n
        fg3_pct_mavg_back_season_n
        fg3_pct_mavg_rank
        fg3_pct_mavg_delta_baseline_rank
        fg3_pct_mavg_delta_short_term_rank
        fg3_pct_mavg_delta_long_term_rank
        fg3_pct_mavg_delta_season_rank
        fg3_pct_mavg_delta_baseline
        fg3_pct_mavg_delta_short_term
        fg3_pct_mavg_delta_long_term
        fg3_pct_mavg_delta_season
        fg3_pct_delta_season
        fg3_pct_delta_season_rank
      }
    }
    leagueStats {
      season_id
      fg2_pct
      fg2m
      fg2a
      fg3_pct
      fg3m
      fg3a
      efg_pct
      fgm
      fga
    }
  }
`;

export default function HomePage() {
  const [filteredTeamId] = useQueryParam("t", NumberParam);
  const [recentFilter = true, setRecentFilter] = useQueryParam(
    "recent",
    BooleanParam
  );
  const handleRecentChange = (newFilter) =>
    setRecentFilter(newFilter, "replaceIn");
  const latestSeasonId = useLatestSeasonId();

  return (
    <Container>
      <Query
        query={ALL_TEAMS_AND_PLAYERS}
        variables={{ season: latestSeasonId }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <Layout>
                <Helmet>
                  <title>Shotline: NBA Shooting Vis</title>
                </Helmet>
                <Flex justifyContent="center" alignItems="center" height={100}>
                  <LoadingSpinner />
                </Flex>
              </Layout>
            );
          }
          if (error) {
            return <Box>Error</Box>;
          }

          if (process.env.NODE_ENV === "development") {
            console.log("[query] HomePage", data);
          }

          const allPlayers = data.players;
          const allTeams = data.teams;
          let filteredPlayers = allPlayers;
          let filterTeam;
          if (filteredTeamId) {
            filteredPlayers = allPlayers.filter(
              (player) => player.team.id === filteredTeamId
            );
            filterTeam = allTeams.find((team) => team.id === filteredTeamId);
          }
          const leagueStats = data.leagueStats;
          return (
            <HomePageInner
              allPlayers={filteredPlayers}
              filterTeam={filterTeam}
              leagueStats={leagueStats}
              latestSeasonId={latestSeasonId}
              recentFilter={recentFilter}
              onChangeRecentFilter={handleRecentChange}
            />
          );
        }}
      </Query>
    </Container>
  );
}

const LeagueHeading = ({ seasonLeagueStats, latestSeasonId, recentFilter }) => (
  <Flex alignItems="center" justifyContent="center" flexDirection="column">
    <Heading as="h1">{`${seasonFormat(latestSeasonId)} ${
      recentFilter ? "Active " : ""
    }NBA Players`}</Heading>
    <Box fontSize={1} color="gray.6">
      <InlineText color="2ptFg" mr={2}>
        <InlineText fontWeight={600}>
          {pctFormat(seasonLeagueStats.fg2_pct)}
        </InlineText>{" "}
        2P%
      </InlineText>{" "}
      <InlineText color="3ptFg" mr={2}>
        <InlineText fontWeight={600}>
          {pctFormat(seasonLeagueStats.fg3_pct)}
        </InlineText>{" "}
        3P%
      </InlineText>
      League Average
    </Box>
  </Flex>
);

const TeamHeading = ({ team }) => (
  <Flex alignItems="center" justifyContent="center">
    <Heading as="h1">{team.full_name}</Heading>
    <IconButton
      key={"close"}
      variant="gray"
      circle
      fontSize={3}
      ml={2}
      to="/"
      as={RouterLink}
      aria-label="Remove team filter"
    >
      <IconClose />
    </IconButton>
  </Flex>
);

const PlayerStatPanels = ({ players, noMaxHeight, recentFilter }) => (
  <Grid
    gridTemplateColumns={`repeat(auto-fill, minmax(300px, 1fr))`}
    gridGap={4}
    mx="auto"
    style={{ maxWidth: "870px" }}
  >
    {deltaTypes.map((deltaType) => {
      return ptTypes.map((shotPts) => {
        return (
          <PlayerStatPanel
            key={`${deltaType}-${shotPts}`}
            noMaxHeight={noMaxHeight}
            players={players}
            recentFilter={recentFilter}
            shotPts={shotPts}
            deltaType={deltaType}
          />
        );
      });
    })}
  </Grid>
);

const HomePageInner = ({
  allPlayers,
  filterTeam,
  leagueStats,
  latestSeasonId,
  recentFilter,
  onChangeRecentFilter,
}) => {
  const players = allPlayers;

  return (
    <Layout>
      <Helmet>
        <title>
          {filterTeam ? `${filterTeam.full_name} - ` : ""}Shotline: NBA Shooting
          Vis
        </title>
      </Helmet>
      <Box>
        <Box my={5}>
          {filterTeam ? (
            <TeamHeading team={filterTeam} />
          ) : (
            <LeagueHeading
              recentFilter={recentFilter}
              latestSeasonId={latestSeasonId}
              seasonLeagueStats={leagueStats[leagueStats.length - 1]}
            />
          )}
        </Box>
        <CardContainer>
          <Box mb={4}>
            <TopPlayers players={players} recentFilter={recentFilter} />
          </Box>

          {filterTeam ? null : (
            <Flex
              justifyContent="center"
              textAlign="center"
              mx="auto"
              mt={6}
              mb={4}
            >
              <label>
                <input
                  type="checkbox"
                  checked={!!recentFilter}
                  onChange={() => onChangeRecentFilter(!recentFilter)}
                />{" "}
                Only include active shooters
              </label>
            </Flex>
          )}

          <PlayerStatPanels
            players={players}
            recentFilter={recentFilter}
            noMaxHeight={!!filterTeam}
          />
        </CardContainer>
      </Box>
    </Layout>
  );
};
