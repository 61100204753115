import React from "react";
import styled from "styled-components/macro";
import { scaleLinear } from "d3-scale";
import { extents } from "../scales";
import { Spring, animated, interpolate } from "react-spring/renderprops";

const StyledPathArrow = styled(animated.path)`
  stroke: ${(props) => props.theme.colors.gray[5]};
  pointer-events: none;
  fill: none;
`;

const ShotPtsLine = styled(animated.line)`
  stroke: ${(props) => props.theme.colors[`${props.shotPts}ptFg`]};
  stroke-width: 1.5px;
`;

const StyledFgPctCircle = styled(animated.circle)`
  fill: ${(props) => props.theme.colors[`${props.shotPts}ptFg`]};
`;

const StyledDeltaCircle = styled(animated.circle)`
  fill: ${(props) => props.theme.colors[`${props.shotPts}ptFg`]};
  fill-opacity: 0.6;
`;

const ChartReferenceLine = styled.line`
  stroke: ${(props) => props.theme.colors.gray[1]};
`;

function PathArrow({
  x1,
  x2,
  /** For when x2 points to a circle, how far back we should end the arrow */
  x2Radius = 0,
  x1Radius = 0,
}) {
  if (x1 == null || x2 == null) {
    return null;
  }
  const arrowHeight = 3.5;
  const arrowWidth = arrowHeight;

  let arrowD = interpolate([x1, x2], (x1, x2) => {
    const x2Offset = x1 < x2 ? -x2Radius : x2Radius;
    const x1Offset = x1 < x2 ? x1Radius : -x1Radius;

    // no arrow if too short
    if (Math.abs(x1 - x2) < arrowWidth * 3) {
      return `M${x1 + x1Offset},0 L${x2 + x2Offset},0`;
    } else {
      // switch arrow direction based on
      return x1 < x2
        ? `M${x1 + x1Offset},0 L${
            x2 + x2Offset
          },0 m${-arrowWidth},${-arrowHeight} l${arrowWidth},${arrowHeight} l${-arrowWidth},${arrowHeight}`
        : `M${x1 + x1Offset},0 L${
            x2 + x2Offset
          },0  m${arrowWidth},${-arrowHeight} l${-arrowWidth},${arrowHeight} l${arrowWidth},${arrowHeight}`;
    }
  });

  return <StyledPathArrow d={arrowD} />;
}

export default function DeltaDotChart({
  width = 250,
  height = 100,
  stats,
  shotPts,
  extent = extents.fg_pct,
}) {
  const baseRadius = 4;
  const deltaRadius = 3;
  const padding = { top: 0, bottom: 0, left: baseRadius, right: baseRadius };
  const plotAreaWidth = width - padding.left - padding.right;
  const plotAreaHeight = height - padding.top - padding.bottom;

  const xScale = scaleLinear().domain(extent).range([0, plotAreaWidth]);

  const rowHeight = plotAreaHeight / 5;
  const rows = [
    stats[`fg${shotPts}_pct_mavg_back_short_term`],
    stats[`fg${shotPts}_pct_mavg_back_long_term`],
    stats[`fg${shotPts}_pct_back_season`],
    stats[`fg${shotPts}_pct_baseline`],
  ];
  const base = stats[`fg${shotPts}_pct_season`];

  return (
    <svg width={width} height={height}>
      <g transform={`translate(${padding.left} ${padding.top})`}>
        <g>
          {rows.map((row, i) => (
            <ChartReferenceLine
              key={i}
              y1={(i + 1) * rowHeight + rowHeight / 2}
              y2={(i + 1) * rowHeight + rowHeight / 2}
              x1={"0%"}
              x2={"100%"}
            />
          ))}
        </g>
        <Spring native to={{ baseX: xScale(base), deltaX: xScale(base + 0.2) }}>
          {({ baseX }) => (
            <g transform={`translate(0 ${rowHeight / 2})`}>
              <ChartReferenceLine x1={"0%"} x2={"100%"} />
              <ShotPtsLine
                x1={baseX}
                x2={baseX}
                y1={0}
                y2={plotAreaHeight - rowHeight * 0.6}
                shotPts={shotPts}
              />
              <StyledFgPctCircle
                shotPts={shotPts}
                r={baseRadius}
                cy={0}
                cx={baseX}
              />
            </g>
          )}
        </Spring>

        {rows.map((row, i) =>
          row == null ? null : (
            <Spring
              key={i}
              native
              to={{ baseX: xScale(base), deltaX: xScale(row) }}
            >
              {({ baseX, deltaX }) => (
                <g
                  key={i}
                  transform={`translate(0 ${
                    (i + 1) * rowHeight + rowHeight / 2
                  })`}
                >
                  <PathArrow
                    x1={deltaX}
                    x2={baseX}
                    x2Radius={1}
                    x1Radius={deltaRadius}
                  />
                  <StyledDeltaCircle
                    shotPts={shotPts}
                    r={deltaRadius}
                    cy={0}
                    cx={deltaX}
                  />
                </g>
              )}
            </Spring>
          )
        )}
      </g>
    </svg>
  );
}
