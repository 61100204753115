import React from "react";
import {
  Box,
  SvgText,
  ValueList,
  ValueListItem,
  ValueListItemLabel,
} from "./core";

import { curveCatmullRom, area as d3_area, line as d3_line } from "d3-shape";
import { scaleLinear } from "d3-scale";
import { Spring, config } from "react-spring/renderprops";
import { pctFormat0Symbol, deltaPctFormat0, seasonFormat } from "../format";
import styled from "styled-components/macro";
import { Grid } from "@vx/grid";
import { Group } from "@vx/group";
import { AxisLeft, AxisBottom } from "@vx/axis";
import { Bar } from "@vx/shape";
import { localPoint } from "@vx/event";
import { theme } from "../theme";
import { colorScales } from "../scales";
import AnimatedPath from "./AnimatedPath";
import { sampleDataset } from "../util";
import SeasonMarkers from "./charts/SeasonMarkers";
import TeamMarkers from "./charts/TeamMarkers";
import { numTicksForHeight, numTicksForWidth, chartCss } from "./charts/charts";
import { movingAverageWindow } from "../config";
import { max, min } from "d3-array";

const aboveBaselineColor = theme.colors.teal[5];
const belowBaselineColor = theme.colors.pink[5];
const tooltipColor = theme.colors.indigo[5];
const beforeBaselineColor = theme.colors.gray[5];
const springConfig = config.default;

const TooltipLine = styled.line`
  stroke: ${(props) => props.theme.colors.gray[6]};
  stroke-opacity: 0.2;
  pointer-events: none;
`;

const TooltipCircle = styled.circle`
  stroke: ${(props) => props.seriesColor};
  stroke: white;
  fill: ${(props) => props.seriesColor};
  pointer-events: none;
`;

const Baseline = styled.line`
  stroke: ${(props) => props.theme.colors.gray[6]};
  stroke-dasharray: 4 2;
`;

const TooltipBox = styled(Box).attrs(({ left, top, bottom, right }) => ({
  style: {
    left,
    top,
    bottom,
    right,
  },
}))`
  position: absolute;
  white-space: nowrap;
  pointer-events: none;
  background: #fff;
  color: ${(props) => props.theme.colors.bodyText};
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  padding: ${(props) => props.theme.space[2]}px
    ${(props) => props.theme.space[3]}px;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadows.small};
  z-index: 1000;
`;

const Tooltip = ({
  player,
  xScale,
  shotPts,
  plotAreaHeight,
  plotAreaWidth,
  data,
  x,
  y,
}) => {
  const numShots = data.player_shot_index;
  const value = data.fg_pct_mavg;
  const season = data.season_id;
  const team = data.team_abbr;
  const formattedValue = pctFormat0Symbol(value);
  const bottom = plotAreaHeight - y;
  const yMargin = 20;
  const xMargin = 10;
  let left;
  let right;
  if (x > plotAreaWidth / 2) {
    right = plotAreaWidth - x + xMargin;
  } else {
    left = x + xMargin;
  }

  const minShots = Math.max(1, numShots - movingAverageWindow);
  const maxShots = Math.max(1, numShots);

  return (
    <TooltipBox left={left} right={right} bottom={bottom + yMargin}>
      <ValueList>
        <ValueListItem>
          <ValueListItemLabel
            width={"4em"}
          >{`${shotPts}P%`}</ValueListItemLabel>
          {formattedValue}
        </ValueListItem>
        <ValueListItem>
          <ValueListItemLabel width={"4em"}>Shots</ValueListItemLabel>
          {minShots === maxShots ? "" : `${minShots.toLocaleString()} – `}
          {maxShots.toLocaleString()}
        </ValueListItem>
        <ValueListItem>
          <ValueListItemLabel width={"4em"}>Season</ValueListItemLabel>
          {seasonFormat(season)}
        </ValueListItem>
        <ValueListItem>
          <ValueListItemLabel width={"4em"}>Team</ValueListItemLabel>
          {team}
        </ValueListItem>
      </ValueList>
    </TooltipBox>
  );
};

let idGen = 0;
const PlayerTimeSeries = ({
  player,
  shotPts,
  width = 700,
  minBaseline,
  height = 300,
  chartType = "full",
  baselineType = "baseline",
  ...other
}) => {
  const statKey = "fg_pct_mavg";
  const xKey = "player_shot_index";

  const [
    aboveBaselineId,
    belowBaselineId,
    beforeBaselineId,
    afterBaselineId,
  ] = React.useMemo(() => {
    idGen += 1;
    return [
      `above-baseline-${idGen}`,
      `below-baseline-${idGen}`,
      `before-baseline-${idGen}`,
      `after-baseline-${idGen}`,
    ];
  }, []);
  // set the baseline (possibly overwritten by a tooltip)
  const baselineKey = `fg${shotPts}_pct_baseline`;
  let baseline = player.derivedPlayerStats[baselineKey] || 0;

  const dataPoints = React.useMemo(() => {
    const points = player.derivedPlayerShotStats.filter(
      (d) => d.shot_pts === shotPts
    );
    // return points;
    return sampleDataset(points, Math.ceil(width / 3));
  }, [player, shotPts, width]);

  const xMax = dataPoints.length
    ? dataPoints[dataPoints.length - 1].player_shot_index
    : 0;

  const afterBaselineX =
    player.derivedPlayerStats[`fg${shotPts}_baseline_n`] || minBaseline;

  // bounds
  const margin = {
    top: 40,
    left: 34,
    right: 50,
    bottom: 43,
  };
  const plotAreaWidth = width - margin.left - margin.right;
  const plotAreaHeight = height - margin.top - margin.bottom;

  const yMax = React.useMemo(() => {
    return max(dataPoints, (d) =>
      xMax < afterBaselineX || d[xKey] >= afterBaselineX ? d[statKey] : 0
    );
  }, [dataPoints, statKey]);
  const yMin = React.useMemo(() => {
    return min(dataPoints, (d) =>
      xMax < afterBaselineX || d[xKey] >= afterBaselineX ? d[statKey] : 1
    );
  }, [dataPoints, statKey]);

  // scales
  const xScale = scaleLinear()
    .domain([0, xMax])
    .range([0, plotAreaWidth])
    .clamp(true);
  const yScale = scaleLinear()
    .domain([Math.min(yMin, 0.15), Math.max(yMax, 0.85)])
    .range([plotAreaHeight, 0])
    .nice();

  const allShotsLine = React.useMemo(() => {
    const points = dataPoints
      .map((d) => ({
        ...d,
        x: d.player_shot_index,
        y: d[statKey],
      }))
      .filter((d) => d[statKey] != null);

    return {
      key: "all",
      player,
      points,
    };
  }, [dataPoints, player, statKey]);
  const lastAnimatedShotsLine = React.useRef(allShotsLine);
  React.useEffect(() => {
    lastAnimatedShotsLine.current = allShotsLine;
  }, [allShotsLine]);

  const [tooltip, setTooltip] = React.useState(null);
  // given the tooltip x, find the corresponding data point
  const tooltipData = React.useMemo(() => {
    if (!tooltip) {
      return null;
    }
    let distance = Infinity;
    let result = undefined;
    for (const d of dataPoints) {
      const newDist = Math.abs(d[xKey] - tooltip.tooltipX);
      if (newDist < distance) {
        distance = newDist;
        result = d;
      } else {
        break;
      }
    }

    return result;
  }, [dataPoints, tooltip]);

  // override what the baseline is if we have tooltip data
  if (tooltipData && tooltipData[statKey] != null) {
    baseline = tooltipData[statKey];
  }

  // path d generator for a line
  const makeLinePath = d3_line()
    .x((d, j) => xScale(d.x))
    .y((d) => yScale(d.y))
    .curve(curveCatmullRom);

  // path d generator for an area
  const makeAreaPathAbove = d3_area()
    .x((d, j) => xScale(d.x))
    .y1((d) => yScale(d.y))
    .y0((d) => plotAreaHeight) //yScale(baseline))
    .curve(curveCatmullRom);
  const makeAreaPathBelow = d3_area()
    .x((d, j) => xScale(d.x))
    .y1((d) => yScale(d.y))
    .y0((d) => 0) //yScale(baseline))
    .curve(curveCatmullRom);

  // callback for mousemove to set a tooltip
  const handleMouseMove = React.useCallback(
    (event) => {
      const coords = localPoint(event.target.ownerSVGElement, event);
      const plotX = coords.x - margin.left;
      // const plotY = coords.y - margin.top;
      const newX = Math.round(xScale.invert(plotX));
      if (!tooltip || (tooltip && tooltip.tooltipX !== newX)) {
        setTooltip({
          tooltipX: newX,
        });
      }
    },
    [xScale, margin.left, tooltip]
  );

  const currentValue = dataPoints.length
    ? dataPoints[dataPoints.length - 1][statKey]
    : 0;
  const colorScale = colorScales.delta;

  return (
    <Box css={chartCss} {...other}>
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <SeasonMarkers
            xScale={xScale}
            plotAreaHeight={plotAreaHeight}
            data={dataPoints}
            xKey={xKey}
          />
          {/* Grid ticks */}
          <Grid
            xScale={xScale}
            yScale={yScale}
            width={plotAreaWidth}
            height={plotAreaHeight}
            numTicksRows={numTicksForHeight(height)}
            numTicksColumns={0} //numTicksForWidth(width)}
          />
          {/* clip paths and baseline */}

          <Spring to={{ baseline }} config={springConfig}>
            {(spring) => (
              <>
                <defs>
                  <clipPath id={beforeBaselineId}>
                    <rect
                      x={0}
                      y={0}
                      width={xScale(afterBaselineX)}
                      height={plotAreaHeight}
                    />
                  </clipPath>
                  <clipPath id={afterBaselineId}>
                    <rect
                      x={xScale(afterBaselineX)}
                      y={0}
                      width={plotAreaWidth}
                      height={plotAreaHeight}
                    />
                  </clipPath>
                  <clipPath id={aboveBaselineId}>
                    <rect
                      x={0}
                      y={0}
                      width={plotAreaWidth}
                      height={yScale(spring.baseline)}
                    />
                  </clipPath>
                  <clipPath id={belowBaselineId}>
                    <rect
                      x={0}
                      y={yScale(spring.baseline)}
                      width={plotAreaWidth}
                      height={plotAreaHeight - yScale(spring.baseline)}
                    />
                  </clipPath>
                </defs>

                <Baseline
                  x1={0}
                  x2={xScale(xMax)}
                  y1={yScale(spring.baseline)}
                  y2={yScale(spring.baseline)}
                />
                {player.derivedPlayerStats[baselineKey] == null &&
                baseline === 0 ? null : (
                  <SvgText
                    x={plotAreaWidth}
                    dx={4}
                    y={yScale((currentValue + spring.baseline) / 2)}
                    textAnchor="start"
                    verticalAnchor="start"
                    fontSize={0}
                    fontWeight="700"
                    style={{ fill: colorScale(currentValue - spring.baseline) }}
                  >
                    {deltaPctFormat0(currentValue - spring.baseline)}
                  </SvgText>
                )}
              </>
            )}
          </Spring>

          {/* The data lines */}
          <g>
            <Spring
              native
              from={{ t: 0 }}
              reset={lastAnimatedShotsLine.current !== allShotsLine}
              to={{ t: 1 }}
            >
              {(spring) => {
                const dLine = makeLinePath(allShotsLine.points);
                const dAreaAbove = makeAreaPathAbove(allShotsLine.points);
                const dAreaBelow = makeAreaPathBelow(allShotsLine.points);
                return (
                  <React.Fragment key={allShotsLine.key}>
                    <Group clipPath={`url(#${beforeBaselineId})`}>
                      <AnimatedPath
                        t={spring.t}
                        d={dLine}
                        fill="none"
                        stroke={beforeBaselineColor}
                        strokeWidth={1}
                      />
                    </Group>
                    <Group clipPath={`url(#${afterBaselineId})`}>
                      <Group clipPath={`url(#${aboveBaselineId})`}>
                        <AnimatedPath
                          t={spring.t}
                          d={dAreaAbove}
                          areaWidth={plotAreaWidth}
                          fillOpacity={0.2}
                          fill={aboveBaselineColor}
                          stroke="none"
                        />
                        <AnimatedPath
                          t={spring.t}
                          d={dLine}
                          fill="none"
                          stroke={aboveBaselineColor}
                          strokeWidth={2}
                        />
                      </Group>
                      <Group clipPath={`url(#${belowBaselineId})`}>
                        <AnimatedPath
                          t={spring.t}
                          d={dAreaBelow}
                          areaWidth={plotAreaWidth}
                          fillOpacity={0.2}
                          fill={belowBaselineColor}
                          stroke="none"
                        />
                        <AnimatedPath
                          t={spring.t}
                          d={dLine}
                          fill="none"
                          stroke={belowBaselineColor}
                          strokeWidth={2}
                        />
                      </Group>
                    </Group>
                  </React.Fragment>
                );
              }}
            </Spring>
          </g>
          <TeamMarkers
            xScale={xScale}
            data={dataPoints}
            xKey={xKey}
            plotAreaHeight={plotAreaHeight}
          />

          {/* Tooltip in graph */}
          {tooltip && tooltipData && (
            <Group left={xScale(tooltipData[xKey])}>
              <TooltipLine y1={0} y2={plotAreaHeight} />

              <TooltipCircle
                cy={yScale(tooltipData[statKey])} //yScale(getY(line.point))}
                r={3.5}
                seriesColor={tooltipColor}
              />
            </Group>
          )}
        </Group>

        {/* Axes */}
        <Group left={margin.left}>
          <AxisLeft
            top={margin.top}
            left={0}
            scale={yScale}
            numTicks={numTicksForHeight(height)}
            stroke="#1b1a1e"
            tickStroke="#8e205f"
            hideZero={false}
            hideTicks
            hideAxisLine
            tickLength={0}
            tickFormat={pctFormat0Symbol}
            tickComponent={({ formattedValue, ...tickProps }) => (
              <text {...tickProps}>{formattedValue}</text>
            )}
          />
          <AxisBottom
            top={height - margin.bottom}
            left={0}
            scale={xScale}
            numTicks={numTicksForWidth(width)}
            label="Shots"
            labelOffset={14}
          />
        </Group>

        {/* Interaction rect */}
        <Bar
          x={margin.left}
          y={margin.top}
          width={plotAreaWidth + 1}
          height={plotAreaHeight}
          fill="transparent"
          onMouseMove={handleMouseMove}
          onMouseLeave={(event) => setTooltip(null)}
        />
      </svg>
      {/* Tooltip layer */}
      {tooltipData && (
        <Box
          mt={margin.top}
          ml={margin.left}
          mr={`${margin.right}px`}
          mb={margin.bottom}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: `${plotAreaWidth}px`,
            height: `${plotAreaHeight}px`,
            pointerEvents: "none",
          }}
        >
          <Tooltip
            player={player}
            xScale={xScale}
            shotPts={shotPts}
            plotAreaWidth={plotAreaWidth}
            plotAreaHeight={plotAreaHeight}
            data={tooltipData}
            x={xScale(tooltipData[xKey])}
            y={yScale(tooltipData[statKey])}
          />
        </Box>
      )}
    </Box>
  );
};
PlayerTimeSeries.whyDidYouRender = false; //{ logOnDifferentValues: true };
export default React.memo(PlayerTimeSeries);
