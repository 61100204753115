import "styled-components/macro";
import { css } from "styled-components/macro";
import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  InlineNumberText,
  ValueColumnHeader,
  NumberTd,
  StyledTable,
  ChartTd,
  Th,
  Td,
  SvgText,
  Text,
  InlineText,
} from "./core";
import { longTermFga, shortTermFga, movingAverageWindow } from "../config";
import { deltaPctFormat0, pctFormat0Symbol } from "../format";
import DeltaDotChart from "./DeltaDotChart";
import { colorScales } from "../scales";
import { Group } from "@vx/group";
import { scaleLinear } from "d3-scale";
import { extents } from "../scales";

const DeltaValue = ({
  base,
  value,
  baseN,
  format = deltaPctFormat0,
  deltaColorScale = colorScales.delta,
}) => {
  if (baseN == null || base == null || value == null) {
    return null;
  }
  const delta = base - value;
  return (
    <InlineNumberText
      fontWeight={700}
      style={{ color: deltaColorScale(delta) }}
    >
      {format(delta)}
    </InlineNumberText>
  );
};

const Value = ({
  value,
  valueFormat = pctFormat0Symbol,
  colorScale,
  ...other
}) => {
  if (value == null) {
    return null;
  }
  return (
    <InlineNumberText {...other} style={{ color: colorScale(value) }}>
      {valueFormat(value)}
    </InlineNumberText>
  );
};

const mobileHide = css`
  @media (max-width: 40em) {
    display: none;
  }
`;

const rowPadding = 1;
const PlayerShootingSummaryTable = ({ player, shotPts = 3 }) => {
  const { derivedPlayerStats } = player;
  const tickScale = scaleLinear().domain(extents.fg_pct).range([0, 242]);

  return (
    <StyledTable width="100%">
      <thead>
        <tr>
          <th />
          <ValueColumnHeader textAlign="center" width={45}>
            {shotPts}P%
          </ValueColumnHeader>
          <ValueColumnHeader textAlign="center" width={50}>
            ↑ / ↓
          </ValueColumnHeader>
          <th css={mobileHide}>
            <svg width={250} height={16}>
              <Group left={4}>
                <SvgText
                  fill="gray.5"
                  fontWeight={400}
                  fontSize={0}
                  x={tickScale(0.3)}
                >
                  30%
                </SvgText>
                <SvgText
                  fill="gray.5"
                  fontWeight={400}
                  fontSize={0}
                  x={tickScale(0.4)}
                >
                  40%
                </SvgText>
                <SvgText
                  fill="gray.5"
                  fontWeight={400}
                  fontSize={0}
                  x={tickScale(0.5)}
                >
                  50%
                </SvgText>
                <SvgText
                  fill="gray.5"
                  fontWeight={400}
                  fontSize={0}
                  x={tickScale(0.6)}
                >
                  60%
                </SvgText>
                <SvgText
                  fill="gray.5"
                  fontWeight={400}
                  fontSize={0}
                  x={tickScale(0.7)}
                >
                  70%
                </SvgText>
              </Group>
            </svg>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <Th fontWeight="400" py={rowPadding}>
            Latest
          </Th>
          <NumberTd py={rowPadding} fontWeight="bold" textAlign="center">
            <Value
              value={derivedPlayerStats[`fg${shotPts}_pct_season`]}
              colorScale={colorScales[`fg${shotPts}_pct`]}
            />
          </NumberTd>
          <td />

          <ChartTd valign="top" rowSpan={5} p={0} width={250} css={mobileHide}>
            <AutoSizer>
              {({ width, height }) => (
                <DeltaDotChart
                  width={width}
                  height={height}
                  stats={derivedPlayerStats}
                  shotPts={shotPts}
                />
              )}
            </AutoSizer>
          </ChartTd>
        </tr>
        <tr>
          <Th fontWeight="400" py={rowPadding}>
            {shortTermFga} shots ago
          </Th>

          <NumberTd py={rowPadding} textAlign="center">
            <Value
              value={
                derivedPlayerStats[`fg${shotPts}_pct_mavg_back_short_term`]
              }
              colorScale={colorScales[`fg${shotPts}_pct`]}
            />
          </NumberTd>
          <NumberTd py={rowPadding} textAlign="left" pl={1}>
            <DeltaValue
              baseN={derivedPlayerStats[`fg${shotPts}_baseline_n`]}
              value={
                derivedPlayerStats[`fg${shotPts}_pct_mavg_back_short_term`]
              }
              base={derivedPlayerStats[`fg${shotPts}_pct_mavg`]}
            />
          </NumberTd>
        </tr>
        <tr>
          <Th fontWeight="400" py={rowPadding}>
            {longTermFga} shots ago
          </Th>

          <NumberTd py={rowPadding} textAlign="center">
            <Value
              value={derivedPlayerStats[`fg${shotPts}_pct_mavg_back_long_term`]}
              colorScale={colorScales[`fg${shotPts}_pct`]}
            />
          </NumberTd>
          <NumberTd py={rowPadding} textAlign="left" pl={1}>
            <DeltaValue
              baseN={derivedPlayerStats[`fg${shotPts}_baseline_n`]}
              value={derivedPlayerStats[`fg${shotPts}_pct_mavg_back_long_term`]}
              base={derivedPlayerStats[`fg${shotPts}_pct_mavg`]}
            />
          </NumberTd>
        </tr>
        <tr>
          <Th
            fontWeight="400"
            py={rowPadding}
            title={`Difference between the ${movingAverageWindow} shot moving average ending now and the one at the end of last season.`}
          >
            Season delta
          </Th>

          <NumberTd py={rowPadding} textAlign="center">
            <Value
              value={derivedPlayerStats[`fg${shotPts}_pct_back_season`]}
              colorScale={colorScales[`fg${shotPts}_pct`]}
            />
          </NumberTd>
          <NumberTd py={rowPadding} textAlign="left" pl={1}>
            <DeltaValue
              baseN={derivedPlayerStats[`fg${shotPts}_baseline_n`]}
              value={derivedPlayerStats[`fg${shotPts}_pct_back_season`]}
              base={derivedPlayerStats[`fg${shotPts}_pct_season`]}
            />
          </NumberTd>
        </tr>
        <tr>
          <Th
            fontWeight="400"
            py={rowPadding}
            title={`Difference between the ${movingAverageWindow} shot moving average ending now and the player's baseline value.`}
          >
            Career delta
          </Th>

          <NumberTd py={rowPadding} textAlign="center">
            <Value
              value={derivedPlayerStats[`fg${shotPts}_pct_baseline`]}
              colorScale={colorScales[`fg${shotPts}_pct`]}
            />
          </NumberTd>
          <NumberTd py={rowPadding} textAlign="left" pl={1}>
            <DeltaValue
              baseN={derivedPlayerStats[`fg${shotPts}_baseline_n`]}
              value={derivedPlayerStats[`fg${shotPts}_pct_baseline`]}
              base={derivedPlayerStats[`fg${shotPts}_pct_mavg`]}
            />
          </NumberTd>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <Th fontWeight="400" py={rowPadding}>
            Total {shotPts}PA
          </Th>
          <NumberTd
            py={rowPadding}
            textAlign="center"
            colSpan={2}
            valign="middle"
          >
            <Text color="gray.7" lineHeight={1}>
              {(
                player.derivedPlayerStats[`fg${shotPts}_pct_mavg_n`] || 0
              ).toLocaleString()}
            </Text>
          </NumberTd>
          <Td py={rowPadding} valign="middle" css={mobileHide}>
            <Text color="gray.5" pl={4} fontSize={0} lineHeight={1}>
              Last {shotPts}PA on{" "}
              <InlineText color="gray.7">
                {player.derivedPlayerStats[`last_fg${shotPts}a_date`]}
              </InlineText>
            </Text>
          </Td>
        </tr>
      </tbody>
    </StyledTable>
  );
};

export default React.memo(PlayerShootingSummaryTable);
