import * as React from 'react';

/** Context for hovering on shot distance charts */
const ShotDistanceContext = React.createContext(null);
export const ShotDistanceProvider = ({ children }) => {
  const [shotDistance, setShotDistance] = React.useState(null);
  const value = React.useMemo(
    () => ({
      shotDistance,
      setShotDistance,
    }),
    [shotDistance, setShotDistance]
  );

  return (
    <ShotDistanceContext.Provider value={value}>
      {children}
    </ShotDistanceContext.Provider>
  );
};

export const useShotDistance = () => {
  return React.useContext(ShotDistanceContext);
};
