import * as React from "react";
import { pctFormat0Symbol, deltaPctFormat0 } from "../format";
import { filterToRecentPlayers, resolveStatKeys } from "./PlayerDeltaStatTable";
import { maxIndex, minIndex } from "d3-array";
import {
  Flex,
  Heading,
  Text,
  Box,
  Link,
  EllipsisLink,
  Card,
  ButtonRadio,
  Grid,
  NumberText,
  InlineNumberText,
  InlineText,
} from "./core";
import { longTermFga, shortTermFga } from "../config";
import PointBadge from "./PointBadge";
import { colorScales } from "../scales";
import { StringParam, useQueryParam } from "use-query-params";

const order = {
  mostImproved: ["short_term", "long_term", "season", "baseline"],
  best: ["short_term", "long_term", "season"],
};

export const deltaMessages = {
  short_term: `Last ${shortTermFga}`,
  long_term: `Last ${longTermFga}`,
  season: `Season`,
  baseline: `Last ${longTermFga} v Baseline`,
};

function findTopPlayers(players, deltaType, shotPts) {
  const { rankKey, currStatKey } = resolveStatKeys(deltaType, shotPts);
  const validPlayers = players.filter(
    (d) => d.derivedPlayerStats[rankKey] != null
  );

  const mostImproved =
    validPlayers[minIndex(validPlayers, (d) => d.derivedPlayerStats[rankKey])];

  const best =
    validPlayers[
      maxIndex(validPlayers, (d) => d.derivedPlayerStats[currStatKey])
    ];

  return { best, mostImproved };
}

const typeOptions = [
  { value: "best", label: "Best Players" },
  { value: "mostImproved", label: "Most Improved " },
];

const TopPlayers = ({ players, recentFilter }) => {
  const topPlayers = React.useMemo(() => {
    if (!players || !players.length) return undefined;
    const filteredPlayers2 = recentFilter
      ? filterToRecentPlayers(players, 2)
      : players;
    const filteredPlayers3 = recentFilter
      ? filterToRecentPlayers(players, 3)
      : players;

    return {
      short_term2: findTopPlayers(filteredPlayers2, "short_term", 2),
      long_term2: findTopPlayers(filteredPlayers2, "long_term", 2),
      season2: findTopPlayers(filteredPlayers2, "season", 2),
      baseline2: findTopPlayers(filteredPlayers2, "baseline", 2),
      short_term3: findTopPlayers(filteredPlayers3, "short_term", 3),
      long_term3: findTopPlayers(filteredPlayers3, "long_term", 3),
      season3: findTopPlayers(filteredPlayers3, "season", 3),
      baseline3: findTopPlayers(filteredPlayers3, "baseline", 3),
    };
  }, [players, recentFilter]);

  let [topType = "best", setTopType] = useQueryParam("top", StringParam);
  const handleChangeTop = (top) => setTopType(top, "replaceIn");
  if (!topType) topType = "best";

  if (!topPlayers) return null;

  return (
    <div>
      <Box mb={4}>
        <ButtonRadio
          width={360}
          mx="auto"
          fontSize={1}
          options={typeOptions}
          value={topType}
          onChange={handleChangeTop}
        />
      </Box>

      <Grid
        gridTemplateColumns={[
          `repeat(2, 1fr)`,
          order[topType].length === 3
            ? `repeat(${order[topType].length}, 200px)`
            : `repeat(2, 1fr)`,
          `repeat(${order[topType].length}, 200px)`,
        ]}
        gridGap={[1, 4]}
        justifyContent="center"
      >
        {order[topType].map((deltaType) => {
          const shotPts = 2;
          const player = topPlayers[`${deltaType}${shotPts}`][topType];
          return (
            <TopPlayer
              key={deltaType}
              topType={topType}
              deltaType={deltaType}
              player={player}
              shotPts={shotPts}
            />
          );
        })}
        {order[topType].map((deltaType) => {
          const shotPts = 3;
          const player = topPlayers[`${deltaType}${shotPts}`][topType];
          return (
            <TopPlayer
              key={deltaType}
              topType={topType}
              deltaType={deltaType}
              player={player}
              shotPts={shotPts}
            />
          );
        })}
      </Grid>
    </div>
  );
};

export default TopPlayers;

const TopPlayer = ({ player, deltaType, topType, shotPts }) => {
  const { thenStatKey, currStatKey } = resolveStatKeys(deltaType, shotPts);
  const value = player?.derivedPlayerStats[currStatKey];
  const base = player?.derivedPlayerStats[thenStatKey];
  const format = pctFormat0Symbol;
  const formatDelta = deltaPctFormat0;
  return (
    <Card mr={0} mb={0} p={[2, 4]}>
      <Flex justifyContent="center" flexDirection="column" alignItems="center">
        <Text
          fontSize={1}
          bg={`${shotPts}ptBg`}
          color={`${shotPts}ptFg`}
          fontWeight={700}
          py={1}
          px={4}
          mb={4}
          borderRadius={4}
          textAlign="center"
        >
          {deltaType === "baseline"
            ? deltaMessages[deltaType]
            : `${deltaMessages[deltaType]} ${shotPts}P% `}
        </Text>
        {player == null ? (
          <Text color="gray.5" fontWeight={400} fontSize={1} mt={5}>
            Insufficent data
          </Text>
        ) : (
          <>
            <NumberText fontSize={4} fontWeight={600} lineHeight={1} mb={3}>
              {format(value)}
              {base == null ? null : (
                <InlineNumberText
                  ml={1}
                  fontWeight={700}
                  fontSize={1}
                  style={{ color: colorScales.delta(value - base) }}
                >
                  {formatDelta(value - base)}
                </InlineNumberText>
              )}
            </NumberText>
            <EllipsisLink
              fontSize={2}
              fontWeight={600}
              flex="1"
              mb={0}
              to={`/p/${player.id}`}
              textAlign="center"
            >
              {player.name}
            </EllipsisLink>
            <Text color="gray.6" fontSize={1} fontWeight={400}>
              {topType === "best" ? "Best Player" : "Most Improved"}
            </Text>
          </>
        )}
      </Flex>
    </Card>
  );
};
