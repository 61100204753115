import React from 'react';
import { SvgText } from '../core';
import styled from 'styled-components/macro';
import { Group } from '@vx/group';
import { sortedExtentsBy } from '../../util';

const TeamBoxRect = styled.rect`
  fill: ${props =>
    props.active ? props.theme.colors.white : props.theme.colors.gray[0]};
  fill-opacity: 1;
  transition: 0.2s fill linear;
`;

const TeamLeftBorder = styled.rect`
  fill: #000;
  fill-opacity: 0.1;
`;

const FadeRect = styled.rect`
  fill: #fff;
  fill-opacity: 0.75;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 0.2s opacity linear;
`;

const Edge = styled.line`
  stroke: #000;
  stroke-opacity: 0.1;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 0.2s opacity linear;
`;

const TeamMarkers = ({ xScale, data, xKey, plotAreaHeight }) => {
  let teamExtents = React.useMemo(
    () => sortedExtentsBy(data, 'team_abbr', xKey, true),
    [data, xKey]
  );

  const [activeExtentTeam, setActiveExtentTeam] = React.useState(undefined);
  const handleMouseEnter = team => {
    setActiveExtentTeam(team);
  };
  const handleMouseLeave = () => setActiveExtentTeam(undefined);

  // move active to last so they show up on top
  let sortedTeamExtents = teamExtents;
  if (activeExtentTeam) {
    sortedTeamExtents = teamExtents.slice().sort((a, b) => {
      if (a[0] === b[0]) {
        return 0;
      }
      if (a[0] === activeExtentTeam) {
        return 1;
      }
      if (b[0] === activeExtentTeam) {
        return -1;
      }
      return a[1] - b[1];
    });
  }

  const minRectWidth = 36; // needs ~21 to fit letters
  const xMax = xScale.range()[1];

  return (
    <Group>
      {sortedTeamExtents.map(([team, teamMin, teamMax, index]) => {
        const xStart = xScale(teamMin);
        const xEnd = xScale(teamMax);
        let labelWidth = Math.max(minRectWidth, xEnd - xStart);
        if (labelWidth + xStart > xMax) {
          labelWidth = labelWidth = xMax - xStart;
        }
        const boxWidth = Math.max(1, xEnd - xStart);

        const label = team;
        const isActive = activeExtentTeam === team;
        const isInactive = activeExtentTeam && activeExtentTeam !== team;

        return (
          <Group key={index} left={xStart}>
            <Group
              top={-34}
              onMouseEnter={() => handleMouseEnter(team)}
              onMouseLeave={handleMouseLeave}
            >
              <TeamBoxRect
                index={index}
                width={labelWidth}
                height={14}
                active={isActive}
              />
              <TeamLeftBorder height={14} width={1} />
              <SvgText
                dy={1}
                dx={3}
                x={0}
                textAnchor="start"
                fontSize={0}
                fill="gray.6"
                fontWeight={isActive ? 'bold' : undefined}
              >
                {label}
              </SvgText>
            </Group>
            <FadeRect
              x={0}
              width={boxWidth}
              height={plotAreaHeight}
              active={isInactive}
            />
            <Edge
              active={isActive}
              x1={0}
              x2={0}
              y1={-34}
              y2={plotAreaHeight}
              stroke="red"
            />
            <Edge
              active={isActive}
              x1={boxWidth}
              x2={boxWidth}
              y1={-34}
              y2={plotAreaHeight}
            />
          </Group>
        );
      })}
    </Group>
  );
};

export default TeamMarkers;
