import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider } from 'styled-components/macro';
import theme, { GlobalStyle } from './theme';
import { ApolloProvider } from 'react-apollo';
import { QueryParamProvider } from 'use-query-params';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import apolloClient from './apolloClient';
import ReactGA from 'react-ga';
ReactGA.initialize(process.env.REACT_APP_GA);

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

const render = App => {
  ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ThemeProvider theme={theme}>
            <React.Fragment>
              <GlobalStyle />
              <App />
            </React.Fragment>
          </ThemeProvider>
        </QueryParamProvider>
      </Router>
    </ApolloProvider>,
    document.getElementById('root')
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const App = require('./App').default;
    render(App);
  });
}
