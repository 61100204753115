import 'styled-components/macro';
import React from 'react';
import { IconButton } from './core';
import { IconChevronDown, IconChevronUp } from './icons';

const ExpandControl = ({ onChange, expanded, ...other }) => {
  return (
    <IconButton
      px={1}
      {...other}
      onClick={onChange ? () => onChange(!expanded) : undefined}
    >
      {expanded ? <IconChevronUp /> : <IconChevronDown />}
    </IconButton>
  );
};

export default ExpandControl;
