import { config } from "react-spring/renderprops";
import styled from "styled-components/macro";
import { theme } from "../../theme";
import { Box } from "../core";

const aboveBaselineColor = theme.colors.teal[5];
const belowBaselineColor = theme.colors.pink[5];
const tooltipColor = theme.colors.indigo[5];
const beforeBaselineColor = theme.colors.gray[5];
const springConfig = config.default;

export const TooltipLine = styled.line`
  ${(props) =>
    props.stroke == null ? `stroke: ${props.theme.colors.gray[6]};` : null}
  stroke-opacity: ${(props) =>
    props.strokeOpacity == null ? 0.2 : props.strokeOpacity};
  pointer-events: none;
`;

export const TooltipCircle = styled.circle`
  stroke: ${(props) => props.seriesColor};
  stroke: white;
  fill: ${(props) => props.seriesColor};
  pointer-events: none;
`;

export const Baseline = styled.line`
  stroke: ${(props) => props.theme.colors.gray[6]};
  stroke-dasharray: 4 2;
`;

export const TooltipBox = styled(Box).attrs(({ left, top, bottom, right }) => ({
  style: {
    left,
    top,
    bottom,
    right,
  },
}))`
  position: absolute;
  white-space: nowrap;
  pointer-events: none;
  background: #fff;
  color: ${(props) => props.theme.colors.bodyText};
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  padding: ${(props) => props.theme.space[2]}px
    ${(props) => props.theme.space[3]}px;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadows.small};
  z-index: 1000;
`;
