import 'styled-components/macro';
import React from 'react';
import { interpolatePath } from 'd3-interpolate-path';
import { animated } from 'react-spring/renderprops';

// // works when t is updated for each render
// const AnimatedPath = ({ t, d, areaWidth, ...other }) => {
//   const currD = React.useRef(d);
//   const pathInterpolator = React.useMemo(
//     () =>
//       interpolatePath(
//         currD.current,
//         d,
//         areaWidth == null
//           ? undefined
//           : (a, b) => a.x === b.x && a.x === areaWidth
//       ),
//     [d, areaWidth]
//   );
//   React.useEffect(() => {
//     currD.current = pathInterpolator(t);
//   });

//   return <path d={currD.current} {...other} />;
// };
// // export default AnimatedPath;

// works when t is a react-spring interpolator (when native=true)
const AnimatedPathNative = ({ t, d, areaWidth, ...other }) => {
  const currD = React.useRef(d);
  const pathInterpolator = React.useMemo(() => {
    const interpolator = interpolatePath(
      currD.current,
      d,
      areaWidth == null
        ? undefined
        : (a, b) => a.x === b.x && (a.x === 0 || a.x === areaWidth)
    );
    return t => {
      const newD = interpolator(t);
      currD.current = newD;
      return newD;
    };
  }, [d, areaWidth]);

  return <animated.path d={t.interpolate(pathInterpolator)} {...other} />;
};

export default AnimatedPathNative;
