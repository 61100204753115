export const pctFormat0 = (d) => (d == null ? "" : (100 * d).toFixed(0));
export const pctFormat = (d) => (d == null ? "" : (100 * d).toFixed(1));
export const decFormat = (d) => (d == null ? "" : d.toFixed(1));
export const decFormat2 = (d) => (d == null ? "" : d.toFixed(2));
export const pctFormat0Symbol = (d) =>
  d == null ? "" : `${(100 * d).toFixed(0)}%`;
export const pctFormatSymbol = (d) =>
  d == null ? "" : `${(100 * d).toFixed(1)}%`;
export const deltaPctFormat = (d) =>
  d === 0 ? "–" : `${d > 0 ? "▲" : "▼"}\u00A0${Math.abs(100 * d).toFixed(1)}%`;

export const deltaPctFormat0 = (d) =>
  d === 0 ? "–" : `${d > 0 ? "▲" : "▼"}\u00A0${Math.abs(100 * d).toFixed(0)}%`;
export const seasonFormat = (d) => `${d - 1}-${("" + d).slice(2)}`;
