import { localPoint } from "@vx/event";
import { Group } from "@vx/group";
import { Bar } from "@vx/shape";
import { extent } from "d3-array";
import { scaleLinear } from "d3-scale";
import { area as d3_area, curveCatmullRom, line as d3_line } from "d3-shape";
import React from "react";
import { Spring } from "react-spring/renderprops";
import styled from "styled-components/macro";
import { decFormat2, pctFormat } from "../../format";
import { colorScales } from "../../scales";
import { theme } from "../../theme";
import AnimatedPath from "../AnimatedPath";
import { Box, Flex, InlineNumberText, SvgText } from "../core";
import { chartCss, findClosest } from "./charts";
import { useShotDistance } from "./contexts";
import { TooltipCircle, TooltipLine } from "./Tooltip";

const playerColor = theme.colors.primary;
const thicknessColor = theme.colors.green[5];
const tooltipColor = theme.colors.indigo[5];

const ReferenceLine = styled.line`
  stroke: ${(props) => props.theme.colors.gray[5]};
  stroke-opacity: 0.4;
  pointer-events: none;
  stroke-dasharray: 3 2;
`;

const ReferenceLineLabel = styled.text`
  fill: ${(props) => props.theme.colors.gray[5]};
  font-size: ${(props) => props.theme.fontSizes[0]}px;
`;

const TooltipDeltaLine = styled.line`
  stroke: ${(props) => colorScales.delta(props.delta)};
  stroke-width: 4px;
  pointer-events: none;
`;

const Tooltip = ({ plotAreaHeight, plotAreaWidth, data, x, y }) => {
  const { playerPoint } = data;
  const bottom = plotAreaHeight - y;
  const yMargin = 20;
  const xMargin = 10;
  let left;
  let right;
  if (x > plotAreaWidth / 2) {
    right = plotAreaWidth - x + xMargin;
  } else {
    left = x + xMargin;
  }

  return (
    <Box style={{ position: "absolute", bottom: 0, width: "100%" }}>
      <Flex
        fontSize={1}
        justifyContent="space-between"
        width="250px"
        mx="auto"
        p={2}
        color="gray.6"
      >
        <Box flex="1">
          <InlineNumberText color={thicknessColor} fontWeight="bold">
            {playerPoint.thickness}
          </InlineNumberText>{" "}
          FGA
        </Box>
        <Box flex="1">
          <InlineNumberText color={playerColor} fontWeight="bold">
            {pctFormat(playerPoint.y)}
          </InlineNumberText>{" "}
          FG%
        </Box>
        <Box flex="1">
          <InlineNumberText
            fontWeight="bold"
            style={{
              backgroundColor: colorScales.pps(playerPoint.color),
              color:
                playerPoint.color > 0.86 && playerPoint.color < 1.1
                  ? "black"
                  : "white",
              transition: "background-color 0.2s, color 0.2s",
            }}
            px={1}
            borderRadius={4}
          >
            {decFormat2(playerPoint.color)}
          </InlineNumberText>{" "}
          PPS
        </Box>
      </Flex>
    </Box>
  );
};

const ShootingSignature = ({
  player,
  width = 700,
  height = 300,
  activeSeasonId,
  maxDistance = 30,
  ...other
}) => {
  const { derivedPlayerShotDistanceStats } = player;
  let {
    shotDistance: tooltipX,
    setShotDistance: onChangeTooltipX = () => {},
  } = useShotDistance();

  const playerData = React.useMemo(() => {
    return derivedPlayerShotDistanceStats
      .filter(
        (d) => d.season_id === activeSeasonId && d.shot_distance <= maxDistance
      )
      .map((d) => ({
        x: d.shot_distance,
        y: d.fg_pct_smoothed,
        thickness: d.fga_smoothed,
        color: d.pps_smoothed,
        seasonId: d.season_id,
        player,
        d,
      }));
  }, [player, derivedPlayerShotDistanceStats, activeSeasonId, maxDistance]);
  // tooltipX = playerData ? playerData[24].x : undefined;

  // bounds
  const margin = {
    top: 5,
    left: 10,
    right: 10,
    bottom: 15,
  };
  const plotAreaWidth = width - margin.left - margin.right;
  const plotAreaHeight = height - margin.top - margin.bottom;

  // scales
  const xScale = React.useMemo(
    () =>
      scaleLinear()
        .domain([0, maxDistance])
        .range([0, plotAreaWidth])
        .clamp(true),
    [maxDistance, plotAreaWidth]
  );
  const yExtent = React.useMemo(() => {
    const dataExtent = extent(playerData, (d) => d.y);
    return [Math.min(dataExtent[0], 0.2), Math.max(dataExtent[1], 0.8)];
  }, [playerData]);

  const yScale = React.useMemo(
    () => scaleLinear().domain(yExtent).range([plotAreaHeight, 0]).nice(),
    [plotAreaHeight, yExtent]
  );
  const thicknessScale = React.useMemo(
    () =>
      scaleLinear()
        .domain([0, 500])
        .range([0, plotAreaHeight * 0.25]),
    [plotAreaHeight]
  );

  // path d generator for a line
  const makeLinePath = d3_line()
    .x((d) => xScale(d.x))
    .y((d) => yScale(d.y))
    .curve(curveCatmullRom);

  const makeAreaPathAbove = d3_area()
    .x((d, j) => xScale(d.x))
    .y1((d) => yScale(d.y) + 1) // + 1 to prevent a gap
    .y0((d) => yScale(d.y) - thicknessScale(d.thickness))
    .curve(curveCatmullRom);

  const makeAreaPathBelow = d3_area()
    .x((d, j) => xScale(d.x))
    .y1((d) => yScale(d.y) - 1) // - 1 to prevent a gap
    .y0((d) => yScale(d.y) + thicknessScale(d.thickness))
    .curve(curveCatmullRom);

  const lastAnimatedData = React.useRef(playerData);
  React.useEffect(() => {
    lastAnimatedData.current = playerData;
  }, [playerData]);

  const tooltipData = React.useMemo(() => {
    if (tooltipX == null) {
      return null;
    }

    const playerPoint = findClosest(playerData, tooltipX);
    return { playerPoint };
  }, [playerData, tooltipX]);

  // callback for mousemove to set a tooltip
  const handleMouseMove = React.useCallback(
    (event) => {
      const coords = localPoint(event.target.ownerSVGElement, event);
      const plotX = coords.x - margin.left;
      // const plotY = coords.y - margin.top;
      const newX = Math.round(xScale.invert(plotX));
      if (tooltipX !== newX) {
        onChangeTooltipX(newX);
      }
    },
    [xScale, margin.left, tooltipX, onChangeTooltipX]
  );

  const gradientId = `shooting-signature-gradient-${player.id}`;
  return (
    <Box css={chartCss} {...other}>
      <svg width={width} height={height} style={{ overflow: "visible" }}>
        <defs>
          <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
            {playerData.map((d) => {
              const offset = `${(100 * xScale(d.x)) / plotAreaWidth}%`;
              return (
                <stop
                  key={d.x}
                  offset={offset}
                  stopColor={colorScales.pps(d.color)}
                />
              );
            })}
          </linearGradient>
        </defs>
        <Group top={margin.top} left={margin.left}>
          {/* The data lines */}
          <g>
            <Spring
              native
              from={{ t: 0 }}
              reset={lastAnimatedData.current !== playerData}
              to={{ t: 1 }}
            >
              {(spring) => {
                const dLine = makeLinePath(playerData);
                const dAreaAbove = makeAreaPathAbove(playerData);
                const dAreaBelow = makeAreaPathBelow(playerData);
                const opacityTransitionCss =
                  "transition: fill-opacity 0.8s, stroke-opacity 0.3s;";
                return (
                  <g>
                    <AnimatedPath
                      t={spring.t}
                      d={dAreaAbove}
                      fill={`url(#${gradientId})`}
                      fillOpacity={tooltipData ? 0.5 : 1}
                      stroke={"none"}
                      strokeWidth={0}
                      css={opacityTransitionCss}
                    />
                    <AnimatedPath
                      t={spring.t}
                      d={dAreaBelow}
                      fill={`url(#${gradientId})`}
                      fillOpacity={tooltipData ? 0.5 : 1}
                      stroke={"none"}
                      strokeWidth={0}
                      css={opacityTransitionCss}
                    />
                    <AnimatedPath
                      t={spring.t}
                      d={dLine}
                      fill="none"
                      stroke={playerColor}
                      strokeWidth={2}
                      strokeOpacity={tooltipData ? 0.75 : 0}
                      css={opacityTransitionCss}
                    />
                  </g>
                );
              }}
            </Spring>
          </g>

          {/* Tooltip in graph */}
          {tooltipData && (
            <Group left={xScale(tooltipData.playerPoint.x)}>
              <SvgText
                textAnchor="middle"
                fontSize={0}
                fill="gray.7"
                dy={"-1.1em"}
                y={
                  yScale(tooltipData.playerPoint.y) -
                  thicknessScale(tooltipData.playerPoint.thickness)
                }
              >
                {tooltipData.playerPoint.x} ft
              </SvgText>
              <TooltipLine
                stroke={thicknessColor}
                strokeOpacity={1}
                strokeWidth={5}
                y1={
                  yScale(tooltipData.playerPoint.y) +
                  thicknessScale(tooltipData.playerPoint.thickness)
                }
                y2={
                  yScale(tooltipData.playerPoint.y) -
                  thicknessScale(tooltipData.playerPoint.thickness)
                }
              />
              <TooltipLine
                stroke={thicknessColor}
                strokeOpacity={1}
                strokeWidth={1}
                y1={
                  yScale(tooltipData.playerPoint.y) +
                  thicknessScale(tooltipData.playerPoint.thickness)
                }
                y2={
                  yScale(tooltipData.playerPoint.y) +
                  thicknessScale(tooltipData.playerPoint.thickness)
                }
                x1={-5}
                x2={5}
              />
              <TooltipLine
                stroke={thicknessColor}
                strokeOpacity={1}
                strokeWidth={1}
                y1={
                  yScale(tooltipData.playerPoint.y) -
                  thicknessScale(tooltipData.playerPoint.thickness)
                }
                y2={
                  yScale(tooltipData.playerPoint.y) -
                  thicknessScale(tooltipData.playerPoint.thickness)
                }
                x1={-5}
                x2={5}
              />

              <TooltipCircle
                cy={yScale(tooltipData.playerPoint.y)}
                r={3.5}
                seriesColor={tooltipColor}
              />
            </Group>
          )}
        </Group>

        {/* Interaction rect */}
        <Bar
          x={margin.left}
          y={margin.top}
          width={plotAreaWidth + 1}
          height={plotAreaHeight}
          fill="transparent"
          onMouseMove={handleMouseMove}
          onMouseLeave={(event) => onChangeTooltipX(null)}
        />
      </svg>
      {/* Tooltip layer */}
      {tooltipData && (
        <Box
          mt={margin.top}
          ml={`${margin.left}px`}
          mr={`${margin.right}px`}
          mb={0}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: `${plotAreaWidth}px`,
            height: `${plotAreaHeight + margin.bottom}px`,
            pointerEvents: "none",
          }}
        >
          <Tooltip
            plotAreaWidth={plotAreaWidth}
            plotAreaHeight={plotAreaHeight}
            data={tooltipData}
            // x={xScale(tooltipX)}
            x={0}
            y={0}
            // y={yScale(tooltipData.playerPoint.y)}
          />
        </Box>
      )}
    </Box>
  );
};
ShootingSignature.whyDidYouRender = false; //{ logOnDifferentValues: true };
export default React.memo(ShootingSignature);
