import "styled-components/macro";
import React from "react";
import { Box, Flex, Heading, CardDescription } from "./core";
import PointBadge from "./PointBadge";

const ShotPtsPanelHeader = ({
  shotPts,
  children,
  description,
  control,
  alignItems = "center",
}) => {
  return (
    <Flex mb={2} mt={-1} alignItems={alignItems}>
      <PointBadge shotPts={shotPts} mr={4} />
      <Box flex="1">
        <Heading as="h3" flex="1" fontSize={2} color={"gray.7"}>
          {children}
        </Heading>
        {description == null ? null : (
          <CardDescription fontWeight="normal" mb={2}>
            {description}
          </CardDescription>
        )}
      </Box>
      {control}
    </Flex>
  );
};

export default ShotPtsPanelHeader;
