import theme from './theme';
import {
  scaleOrdinal,
  scaleSequential,
  scaleThreshold,
  scaleLinear,
} from 'd3-scale';
import { interpolateRdYlBu } from 'd3-scale-chromatic';

export const ColorSchemes = {
  LINEAR: 'LINEAR',
  DIVERGING_BINARY: 'DIVERGING_BINARY',
};

export const colorSchemes = {
  DIVERGING_BINARY: [
    theme.colors.pink[5],
    theme.colors.gray[5],
    theme.colors.teal[5],
  ],
  LINEAR: [theme.colors.gray[4], theme.colors.gray[9]],
};

export const extents = {
  fg2_pct_partial: [0.35, 0.7], // good for coloring or typical values
  fg3_pct_partial: [0.25, 0.45],
  fg2_pct: [0.3, 0.8],
  fg3_pct: [0.2, 0.55],
  fg_pct: [0.2, 0.8],
  pps_partial: [0.75, 1.25].reverse(),
};

export const colorScales = {
  delta: scaleThreshold()
    .domain([-0.01, 0.01])
    .range(colorSchemes.DIVERGING_BINARY),
  fg2_pct: scaleLinear()
    .domain(extents.fg2_pct_partial)
    .range(colorSchemes.LINEAR)
    .clamp(true),
  fg3_pct: scaleLinear()
    .domain(extents.fg3_pct_partial)
    .range(colorSchemes.LINEAR)
    .clamp(true),
  pps: scaleSequential(interpolateRdYlBu)
    .domain(extents.pps_partial)
    .clamp(true),
  shot_bin: scaleOrdinal()
    .domain([0, 1, 2, 3, 4])
    .range([
      theme.colors.violet[8],
      theme.colors.violet[5],
      theme.colors.violet[3],
      theme.colors.violet[2],
      theme.colors.cyan[5],
    ]),
};

export function scaleColor(stat) {
  if (stat) {
    if (stat.colorScheme === ColorSchemes.DIVERGING_BINARY) {
      return scaleThreshold()
        .domain([0])
        .range(colorSchemes.DIVERGING_BINARY);
    } else if (stat.colorScheme === ColorSchemes.LINEAR) {
      return scaleLinear()
        .domain(stat.extent || [0, 1])
        .range(colorSchemes.LINEAR);
    }
  }

  // default to an empty linear scale
  return scaleLinear().range([]);
}
