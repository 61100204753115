import { css } from "styled-components/macro";

// responsive utils for axis ticks
export function numTicksForHeight(height) {
  if (height <= 300) return 5;
  if (300 < height && height <= 600) return 8;
  return 10;
}

export function numTicksForWidth(width) {
  if (width <= 300) return 2;
  if (300 < width && width <= 400) return 5;
  return 10;
}

export function findClosest(data, value, xKey = "x") {
  let distance = Infinity;
  let result = undefined;
  for (const d of data) {
    const newDist = Math.abs(d[xKey] - value);
    if (newDist < distance) {
      distance = newDist;
      result = d;
    } else {
      break;
    }
  }

  return result;
}

export const chartCss = css`
  position: relative;
  .vx-linepath {
    stroke: ${(props) => props.theme.colors.primary};
    fill: none;
  }

  .vx-area {
    stroke: none;
    fill: ${(props) => props.theme.colors.gray[3]};
    fill-opacity: 0.2;
  }
  .vx-axis .vx-line {
    stroke: ${(props) => props.theme.colors.gray[2]};
  }
  .vx-grid .vx-line {
    stroke: ${(props) => props.theme.colors.gray[1]};
  }
  .vx-axis-tick text {
    font-size: ${(props) => props.theme.fontSizes[0]}px;
    fill: rgba(0, 0, 0, 0.3);
  }
  .vx-axis-label {
    fill: ${(props) => props.theme.colors.gray[6]};
    text-transform: uppercase;
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSizes[0]}px;
  }

  .vx-group text {
    font-family: ${(props) => props.theme.fonts.sans};
  }
`;
