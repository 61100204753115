import "styled-components/macro";
import subDate from "date-fns/sub";
import formatDate from "date-fns/format";
import React from "react";
import {
  Flex,
  NumberText,
  ValueColumnHeader,
  NumberTd,
  StyledTable,
  StatRow,
  EllipsisLink,
  Divider,
  Td,
  Text,
} from "./core";
import { deltaPctFormat0, pctFormat0Symbol } from "../format";
import { colorScales } from "../scales";
import { longTermFga, shortTermFga } from "../config";
import { max } from "d3-array";

export function resolveStatKeys(deltaType, shotPts) {
  let statKey;
  let thenStatKey;
  let currStatKey;

  if (deltaType === "baseline") {
    statKey = `fg${shotPts}_pct_mavg_delta_${deltaType}`;
    thenStatKey = `fg${shotPts}_pct_baseline`;
    currStatKey = `fg${shotPts}_pct_mavg`;
  } else if (deltaType === "short_term") {
    statKey = `fg${shotPts}_pct_mavg_delta_${deltaType}`;
    thenStatKey = `fg${shotPts}_pct_mavg_back_${deltaType}`;
    currStatKey = `fg${shotPts}_pct_mavg_short`;
  } else if (deltaType === "season") {
    statKey = `fg${shotPts}_pct_delta_${deltaType}`;
    thenStatKey = `fg${shotPts}_pct_back_${deltaType}`;
    currStatKey = `fg${shotPts}_pct_season`;
  } else {
    statKey = `fg${shotPts}_pct_mavg_delta_${deltaType}`;
    thenStatKey = `fg${shotPts}_pct_mavg_back_${deltaType}`;
    currStatKey = `fg${shotPts}_pct_mavg`;
  }
  const rankKey = `${statKey}_rank`;

  return { statKey, thenStatKey, rankKey, currStatKey };
}

function sortPlayers(allPlayers, rankKey, sortKey, sortDir = SortDir.desc) {
  if (!allPlayers) return allPlayers;

  const sortedPlayers = allPlayers
    .filter((d) => d.derivedPlayerStats[rankKey] != null)
    .sort((a, b) =>
      sortDir === SortDir.asc
        ? a.derivedPlayerStats[sortKey] - b.derivedPlayerStats[sortKey]
        : b.derivedPlayerStats[sortKey] - a.derivedPlayerStats[sortKey]
    );

  return sortedPlayers;
}

const SortDir = { asc: true, desc: false };

function PlayerDeltaRowItem({ player, deltaType, shotPts, hideDelta }) {
  const deltaColorScale = colorScales.delta;
  const fgpctColorScale = colorScales[`fg${shotPts}_pct`];

  const { statKey, thenStatKey, currStatKey, rankKey } = resolveStatKeys(
    deltaType,
    shotPts
  );

  const deltaValue = player.derivedPlayerStats[statKey];
  const currValue = player.derivedPlayerStats[currStatKey];
  const thenValue = player.derivedPlayerStats[thenStatKey];
  return (
    <StatRow>
      <Td>
        <Flex alignItems="center">
          <NumberText
            mr={2}
            textAlign="right"
            width={30}
            fontSize={0}
            color={"gray.5"}
          >
            {player.derivedPlayerStats[rankKey]}
          </NumberText>
          <EllipsisLink color="bodyText" flex="1" mr={4} to={`/p/${player.id}`}>
            {player.name}
          </EllipsisLink>
        </Flex>
      </Td>
      <NumberTd
        textAlign="center"
        style={{ color: fgpctColorScale(currValue) }}
      >
        {pctFormat0Symbol(currValue)}
      </NumberTd>
      <NumberTd
        textAlign="center"
        style={{ color: fgpctColorScale(thenValue) }}
      >
        {pctFormat0Symbol(thenValue)}
      </NumberTd>
      {hideDelta ? null : (
        <NumberTd
          textAlign="left"
          fontWeight="700"
          pl={1}
          style={{ color: deltaColorScale(deltaValue) }}
        >
          {deltaPctFormat0(deltaValue)}
        </NumberTd>
      )}
    </StatRow>
  );
}

const headers = {
  long_term: [`Previous ${longTermFga}`, `Current ${longTermFga}`],
  short_term: [`Previous ${shortTermFga}`, `Current ${shortTermFga}`],
  season: [`Previous Season`, `Current Season`],
  baseline: [`Baseline`, `Current ${longTermFga}`],
};

export function filterToRecentPlayers(players, shotPts) {
  if (!players || !players.length) return players;

  const attemptKey = `last_fg${shotPts}a_date`;
  const maxDateValue = max(
    players || [],
    (d) => d.derivedPlayerStats[attemptKey]
  );
  const minDate = formatDate(
    subDate(new Date(`${maxDateValue}T00:00:00`), { months: 1 }),
    "yyyy-MM-dd"
  );

  return players.filter(
    (player) => player.derivedPlayerStats[attemptKey] > minDate
  );
}

export default function PlayerDeltaStatTable({
  players,
  deltaType,
  shotPts,
  expanded,
  recentFilter,
  maxPlayers = 5,
}) {
  const { statKey, thenStatKey, rankKey, currStatKey } = resolveStatKeys(
    deltaType,
    shotPts
  );

  const [sortKey, setSortKey] = React.useState(rankKey);
  const [sortDir, setSortDir] = React.useState(SortDir.asc);
  const handleSort = (newSortKey) => {
    const newSortDir =
      newSortKey === sortKey
        ? !sortDir
        : newSortKey === rankKey
        ? SortDir.asc
        : SortDir.desc;
    setSortKey(newSortKey);
    setSortDir(newSortDir);
  };

  const filteredPlayers = React.useMemo(() => {
    if (!players || !recentFilter) return players;
    return filterToRecentPlayers(players, shotPts);
  }, [players, recentFilter, shotPts]);

  const sortedPlayers = React.useMemo(
    () => sortPlayers(filteredPlayers, rankKey, sortKey, sortDir),
    [filteredPlayers, rankKey, sortKey, sortDir]
  );

  if (!players || !players.length) {
    return null;
  }

  const topEnd = Math.min(maxPlayers, sortedPlayers.length);
  const worstBegin = Math.max(topEnd, sortedPlayers.length - maxPlayers);
  const topPlayers = sortedPlayers.slice(0, topEnd);
  const worstPlayers = sortedPlayers.slice(worstBegin);

  let middlePlayers;
  if (expanded) {
    middlePlayers = sortedPlayers.slice(
      maxPlayers,
      sortedPlayers.length - maxPlayers
    );
  }

  const hasData =
    topPlayers.length || worstPlayers.length || middlePlayers?.length;

  if (!hasData && !expanded) {
    return (
      <Text mt={4} color="gray.6">
        Insufficient data for all players. <br />
        Check back in a few days.
      </Text>
    );
  }

  return (
    <StyledTable fixed width="100%">
      <thead>
        <tr>
          <th />
          <ValueColumnHeader width={54} onClick={() => handleSort(currStatKey)}>
            {headers[deltaType][1]}
          </ValueColumnHeader>
          <ValueColumnHeader width={54} onClick={() => handleSort(thenStatKey)}>
            {headers[deltaType][0]}
          </ValueColumnHeader>
          <ValueColumnHeader
            width={50}
            pl={1}
            onClick={() => handleSort(rankKey)}
          >
            ↑ / ↓
          </ValueColumnHeader>
        </tr>
      </thead>
      <tbody>
        {topPlayers.map((player) => (
          <PlayerDeltaRowItem
            key={player.id}
            player={player}
            deltaType={deltaType}
            shotPts={shotPts}
          />
        ))}
        {expanded ? (
          middlePlayers.map((player) => (
            <PlayerDeltaRowItem
              key={player.id}
              player={player}
              deltaType={deltaType}
              shotPts={shotPts}
            />
          ))
        ) : (
          <tr>
            <td colSpan={4}>
              <Divider my={4} />
            </td>
          </tr>
        )}
        {worstPlayers.map((player) => (
          <PlayerDeltaRowItem
            key={player.id}
            player={player}
            deltaType={deltaType}
            shotPts={shotPts}
          />
        ))}
      </tbody>
    </StyledTable>
  );
}

export function PlayerDeltaStatFlatTable({
  players,
  deltaType,
  shotPts,
  hideDelta,
}) {
  if (!players || !players.length) {
    return null;
  }

  return (
    <StyledTable width="100%">
      <thead>
        <tr>
          <th />
          <ValueColumnHeader width={54}>
            {headers[deltaType][1]}
          </ValueColumnHeader>
          <ValueColumnHeader width={54}>
            {headers[deltaType][0]}
          </ValueColumnHeader>
          {hideDelta ? null : (
            <ValueColumnHeader width={50}>↑ / ↓</ValueColumnHeader>
          )}
        </tr>
      </thead>
      <tbody>
        {players.map((player) => (
          <PlayerDeltaRowItem
            key={player.id}
            player={player}
            hideDelta={hideDelta}
            deltaType={deltaType}
            shotPts={shotPts}
          />
        ))}
      </tbody>
    </StyledTable>
  );
}
