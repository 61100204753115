import React from 'react';
import {
  Box,
  ValueList,
  ValueListItem,
  ValueListItemLabel,
  InlineBox,
  InlineText,
} from '../core';

import { curveCatmullRom, line as d3_line } from 'd3-shape';
import { scaleLinear } from 'd3-scale';
import { Spring } from 'react-spring/renderprops';
import {
  pctFormat0Symbol,
  pctFormatSymbol,
  deltaPctFormat,
} from '../../format';
import styled from 'styled-components/macro';
import { Grid } from '@vx/grid';
import { Group } from '@vx/group';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { Bar } from '@vx/shape';
import { localPoint } from '@vx/event';
import { theme } from '../../theme';
import { colorScales } from '../../scales';
import AnimatedPath from '../AnimatedPath';
import {
  numTicksForHeight,
  numTicksForWidth,
  chartCss,
  findClosest,
} from './charts';
import { TooltipLine, TooltipCircle, TooltipBox } from './Tooltip';
import { useShotDistance } from './contexts';

const playerColor = theme.colors.primary;
const leagueColor = theme.colors.gray[5];
const tooltipColor = theme.colors.indigo[5];

const ReferenceLine = styled.line`
  stroke: ${props => props.theme.colors.gray[5]};
  stroke-opacity: 0.4;
  pointer-events: none;
  stroke-dasharray: 3 2;
`;

const ReferenceLineLabel = styled.text`
  fill: ${props => props.theme.colors.gray[5]};
  font-size: ${props => props.theme.fontSizes[0]}px;
`;

const TooltipDeltaLine = styled.line`
  stroke: ${props => colorScales.delta(props.delta)};
  stroke-width: 4px;
  pointer-events: none;
`;

const Tooltip = ({ plotAreaHeight, plotAreaWidth, data, x, y }) => {
  const { playerPoint, leaguePoint } = data;
  const bottom = plotAreaHeight - y;
  const yMargin = 20;
  const xMargin = 10;
  let left;
  let right;
  if (x > plotAreaWidth / 2) {
    right = plotAreaWidth - x + xMargin;
  } else {
    left = x + xMargin;
  }
  const delta = playerPoint.y - leaguePoint.y;
  return (
    <TooltipBox left={left} right={right} bottom={bottom + yMargin}>
      <ValueList>
        <ValueListItem>
          <ValueListItemLabel width={'5em'}>Distance</ValueListItemLabel>
          {playerPoint.x} ft
        </ValueListItem>
        <ValueListItem>
          <ValueListItemLabel color="primary" width={'5em'}>
            Player
          </ValueListItemLabel>
          <InlineBox>
            {pctFormatSymbol(playerPoint.y)}
            <Box color="gray.6" title="Approximate FGM/FGA due to smoothing">
              <InlineText color="gray.5">~</InlineText> {playerPoint.numerator}{' '}
              / {playerPoint.denominator}
            </Box>
          </InlineBox>
        </ValueListItem>
        <ValueListItem>
          <ValueListItemLabel width={'5em'}>League</ValueListItemLabel>
          {pctFormatSymbol(leaguePoint.y)}
        </ValueListItem>
        <ValueListItem>
          <ValueListItemLabel width={'5em'}>∆ League</ValueListItemLabel>
          <InlineText fontWeight="bold" color={colorScales.delta(delta)}>
            {deltaPctFormat(delta)}
          </InlineText>
        </ValueListItem>
      </ValueList>
    </TooltipBox>
  );
};

const PlayerShotDistanceChart = ({
  player,
  leagueStats,
  width = 700,
  height = 300,
  activeSeasonId,
  maxDistance = 30,
  type = 'dist_pct',
  ...other
}) => {
  const { derivedPlayerShotDistanceStats } = player;
  let {
    shotDistance: tooltipX,
    setShotDistance: onChangeTooltipX,
  } = useShotDistance();

  const playerData = React.useMemo(() => {
    return derivedPlayerShotDistanceStats
      .filter(
        d => d.season_id === activeSeasonId && d.shot_distance <= maxDistance
      )
      .map(d => ({
        x: d.shot_distance,
        y: d[`${type}_smoothed`],
        seasonId: d.season_id,
        player,
        numerator: type === 'dist_pct' ? d.avg_fga_smoothed : d.fgm_smoothed,
        denominator: type === 'dist_pct' ? d.total_fga : d.fga_smoothed,
        d,
      }));
  }, [
    player,
    derivedPlayerShotDistanceStats,
    type,
    activeSeasonId,
    maxDistance,
  ]);
  // tooltipX = playerData ? playerData[0].x : undefined;
  const leagueData = React.useMemo(() => {
    return (
      leagueStats.find(
        d => d.derivedLeagueShotDistanceStats[0].season_id === activeSeasonId
      ) || { derivedLeagueShotDistanceStats: [] }
    ).derivedLeagueShotDistanceStats
      .filter(d => d.shot_distance <= maxDistance)
      .map(d => ({
        x: d.shot_distance,
        y: d[`${type}_smoothed`],
        seasonId: d.season_id,
        league: true,
        d,
      }));
  }, [leagueStats, type, activeSeasonId, maxDistance]);

  // bounds
  const margin = {
    top: 15,
    left: 34,
    right: 50,
    bottom: 43,
  };
  const plotAreaWidth = width - margin.left - margin.right;
  const plotAreaHeight = height - margin.top - margin.bottom;

  // scales
  const xScale = React.useMemo(
    () =>
      scaleLinear()
        .domain([0, maxDistance])
        .range([0, plotAreaWidth])
        .clamp(true),
    [maxDistance, plotAreaWidth]
  );
  const yMax = type === 'dist_pct' ? 0.25 : 1;
  const yScale = React.useMemo(
    () =>
      scaleLinear()
        .domain([0, yMax])
        .range([plotAreaHeight, 0])
        .nice(),
    [yMax, plotAreaHeight]
  );

  // path d generator for a line
  const makeLinePath = d3_line()
    .x(d => xScale(d.x))
    .y(d => yScale(d.y))
    .curve(curveCatmullRom);

  const lastAnimatedData = React.useRef(playerData);
  React.useEffect(() => {
    lastAnimatedData.current = playerData;
  }, [playerData]);

  const tooltipData = React.useMemo(() => {
    if (tooltipX == null) {
      return null;
    }

    const playerPoint = findClosest(playerData, tooltipX);
    const leaguePoint = findClosest(leagueData, tooltipX);
    return { playerPoint, leaguePoint };
  }, [playerData, leagueData, tooltipX]);

  // callback for mousemove to set a tooltip
  const handleMouseMove = React.useCallback(
    event => {
      const coords = localPoint(event.target.ownerSVGElement, event);
      const plotX = coords.x - margin.left;
      // const plotY = coords.y - margin.top;
      const newX = Math.round(xScale.invert(plotX));
      if (tooltipX !== newX) {
        onChangeTooltipX(newX);
      }
    },
    [xScale, margin.left, tooltipX, onChangeTooltipX]
  );

  return (
    <Box css={chartCss} {...other}>
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          {/* Grid ticks */}
          <Grid
            xScale={xScale}
            yScale={yScale}
            width={plotAreaWidth}
            height={plotAreaHeight}
            numTicksRows={numTicksForHeight(height)}
            numTicksColumns={0} //numTicksForWidth(width)}
          />
          <ReferenceLine
            x1={xScale(4)}
            x2={xScale(4)}
            y1={-10}
            y2={plotAreaHeight}
          />
          <ReferenceLineLabel
            x={xScale(4)}
            textAnchor="end"
            y={0}
            dx={-4}
            dy={0}
          >
            Restr.
          </ReferenceLineLabel>
          <ReferenceLine
            x1={xScale(15)}
            x2={xScale(15)}
            y1={-10}
            y2={plotAreaHeight}
          />
          <ReferenceLineLabel
            x={xScale(15)}
            textAnchor="end"
            y={0}
            dx={-4}
            dy={0}
          >
            FT Line
          </ReferenceLineLabel>
          <ReferenceLine
            x1={xScale(22)}
            x2={xScale(22)}
            y1={-10}
            y2={plotAreaHeight}
          />
          <ReferenceLineLabel
            x={xScale(22)}
            textAnchor="end"
            y={0}
            dx={-4}
            dy={0}
          >
            Corner
          </ReferenceLineLabel>
          <ReferenceLine
            x1={xScale(23.75)}
            x2={xScale(23.75)}
            y1={-10}
            y2={plotAreaHeight}
          />
          <ReferenceLineLabel
            x={xScale(23.75)}
            y={0}
            dx={4}
            dy={0}
            textAnchor="start"
          >
            3 PT
          </ReferenceLineLabel>

          {/* The data lines */}
          <g>
            <Spring
              native
              from={{ t: 0 }}
              reset={lastAnimatedData.current !== playerData}
              to={{ t: 1 }}
            >
              {spring => {
                const dLine = makeLinePath(playerData);
                const dLeagueLine = makeLinePath(leagueData);
                return (
                  <g>
                    <AnimatedPath
                      t={spring.t}
                      d={dLeagueLine}
                      fill="none"
                      stroke={leagueColor}
                      strokeWidth={1}
                    />
                    <AnimatedPath
                      t={spring.t}
                      d={dLine}
                      fill="none"
                      stroke={playerColor}
                      strokeWidth={2}
                    />
                  </g>
                );
              }}
            </Spring>
          </g>

          {/* Tooltip in graph */}
          {tooltipData && (
            <Group left={xScale(tooltipData.playerPoint.x)}>
              <TooltipLine y1={0} y2={plotAreaHeight} />
              <TooltipDeltaLine
                y1={yScale(tooltipData.playerPoint.y)}
                y2={yScale(tooltipData.leaguePoint.y)}
                delta={tooltipData.playerPoint.y - tooltipData.leaguePoint.y}
              />

              <TooltipCircle
                cy={yScale(tooltipData.playerPoint.y)}
                r={3.5}
                seriesColor={tooltipColor}
              />
            </Group>
          )}
        </Group>

        {/* Axes */}
        <Group left={margin.left}>
          <AxisLeft
            top={margin.top}
            left={0}
            scale={yScale}
            numTicks={numTicksForHeight(height)}
            stroke="#1b1a1e"
            tickStroke="#8e205f"
            hideZero={false}
            hideTicks
            hideAxisLine
            tickLength={0}
            tickFormat={pctFormat0Symbol}
            tickComponent={({ formattedValue, ...tickProps }) => (
              <text {...tickProps}>{formattedValue}</text>
            )}
          />
          <AxisBottom
            top={height - margin.bottom}
            left={0}
            scale={xScale}
            numTicks={numTicksForWidth(width)}
            label="Distance from Rim (ft)"
            labelOffset={14}
          />
        </Group>

        {/* Interaction rect */}
        <Bar
          x={margin.left}
          y={margin.top}
          width={plotAreaWidth + 1}
          height={plotAreaHeight}
          fill="transparent"
          onMouseMove={handleMouseMove}
          onMouseLeave={event => onChangeTooltipX(null)}
        />
      </svg>
      {/* Tooltip layer */}
      {tooltipData && (
        <Box
          mt={margin.top}
          ml={margin.left}
          mr={`${margin.right}px`}
          mb={margin.bottom}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: `${plotAreaWidth}px`,
            height: `${plotAreaHeight}px`,
            pointerEvents: 'none',
          }}
        >
          <Tooltip
            plotAreaWidth={plotAreaWidth}
            plotAreaHeight={plotAreaHeight}
            data={tooltipData}
            x={xScale(tooltipX)}
            y={yScale(tooltipData.playerPoint.y)}
          />
        </Box>
      )}
    </Box>
  );
};
PlayerShotDistanceChart.whyDidYouRender = false; //{ logOnDifferentValues: true };
export default React.memo(PlayerShotDistanceChart);
