import 'styled-components/macro';
import React from 'react';
import { InlineFlex, Text } from './core';

export const PointBadge = ({ shotPts, ...other }) => {
  return (
    <InlineFlex
      flexDirection="column"
      width="2em"
      height="2em"
      borderRadius="2em"
      justifyContent="center"
      alignItems="center"
      backgroundColor={`${shotPts}ptBg`}
      color={`${shotPts}ptFg`}
      {...other}
    >
      <Text fontWeight="700" fontSize={2} lineHeight={1}>
        {shotPts}
      </Text>
      <Text fontSize="0.5rem" lineHeight={1}>
        PT
      </Text>
    </InlineFlex>
  );
};
export default PointBadge;
