import styled, { css } from "styled-components/macro";
import formatDate from "date-fns/format";
import React from "react";
import { Box, Text, Flex, Image, Link, BoundContainer } from "./core";
import logo from "../assets/shotline_logo_words.svg";
import OmniSearch from "./OmniSearch";
import { AppContext } from "../app-context";

const mobileHide = css`
  @media (max-width: 40em) {
    display: none;
  }
`;

const navHeight = 61;
const NavContainer = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: ${navHeight}px;
`;
NavContainer.defaultProps = {
  alignItems: "center",
  backgroundColor: "white",
  py: 2,
  px: 4,
  mb: 4,
  boxShadow: "small",
  justifyContent: "space-between",
};

const Nav = ({ header }) => {
  return (
    <NavContainer>
      <BoundContainer>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Link to="/" variant="basic" mr={4} aria-label="Shotline">
            <Flex alignItems="center">
              <Image src={logo} height={30} alt="Shotline Logo" />
            </Flex>
          </Link>
          <Box css={mobileHide}>{header}</Box>
          <OmniSearch />
        </Flex>
      </BoundContainer>
    </NavContainer>
  );
};

const Layout = ({ children, navHeader }) => {
  const { lastUpdateDate } = React.useContext(AppContext);

  return (
    <Box pt={`${navHeight}px`}>
      <Nav header={navHeader} />
      {children}
      <Text textAlign="center" p={5} my={5} color="gray.6">
        <Text mb={5}>
          <Link to="/about">About</Link>
        </Text>

        <Text mb={5}>
          Made with ♥ by{" "}
          <Link as="a" href="https://peterbeshai.com">
            Peter Beshai
          </Link>
        </Text>
        <Text color="gray.5" fontSize={1}>
          Data last updated on{" "}
          {formatDate(new Date(`${lastUpdateDate}T00:00:00`), "PP")}
        </Text>
      </Text>
    </Box>
  );
};

export default Layout;
