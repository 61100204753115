import React from "react";
import TooltipTrigger from "react-popper-tooltip";
import { Link as RouterLink } from "react-router-dom";
import { darken } from "polished";
import styled, { css } from "styled-components/macro";
import {
  get as ssget,
  style,
  space,
  color,
  width,
  height,
  flex,
  order,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  fontSize,
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  border,
  borders,
  borderColor,
  borderRadius,
  buttonStyle,
  boxShadow,
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  backgroundRepeat,
  opacity,
  variant,
  overflow,
  grid,
} from "styled-system";
import { Group } from "@vx/group";
import { IconClose, IconInfo } from "./icons";

const themed = (key) => (props) => props.theme[key];

export const Box = styled("div").attrs(
  ({ width, height, maxWidth, maxHeight }) => ({
    style: {
      width,
      maxHeight,
      maxWidth,
      height,
    },
  })
)(
  {
    boxSizing: "border-box",
  },
  space,
  fontSize,
  color,
  flex,
  order,
  alignSelf,
  overflow,
  borderRadius,
  boxShadow,
  border,
  borders,
  themed("Box")
);

Box.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...flex.propTypes,
  ...order.propTypes,
  ...alignSelf.propTypes,
  ...borderRadius.propTypes,
};

export const Flex = styled(Box)(
  {
    display: "flex",
  },
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  themed("Flex")
);

Flex.propTypes = {
  ...flexWrap.propTypes,
  ...flexDirection.propTypes,
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
};

export const Grid = styled(Box)(
  {
    display: "grid",
  },
  alignItems,
  justifyContent,
  grid,
  themed("Flex")
);

Grid.propTypes = {
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
  ...grid.propTypes,
};

export const Text = styled(Box)(
  (props) => ({
    whiteSpace: props.whiteSpace,
  }),
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  themed("Text")
);

Text.propTypes = {
  ...fontFamily.propTypes,
  ...fontWeight.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...letterSpacing.propTypes,
};

export const FlexText = styled(Flex)(
  (props) => ({
    whiteSpace: props.whiteSpace,
  }),
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  themed("Text")
);

FlexText.propTypes = {
  ...fontFamily.propTypes,
  ...fontWeight.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...letterSpacing.propTypes,
};

export const Heading = styled(FlexText)(themed("Heading"));
Heading.defaultProps = {
  alignItems: "center",
  as: "h2",
  fontSize: 4,
  fontWeight: "bold",
  lineHeight: 1.25,
  mb: 0,
};

export const Link = styled(Text)(variant({ key: "links" }), themed("Link"));

Link.defaultProps = {
  as: RouterLink,
};

export const Button = styled(Box)(
  {
    appearance: "none",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    lineHeight: "inherit",
    textDecoration: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontWeight: "700",
    padding: (props) => `${props.theme.space[2]}px ${props.theme.space[4]}px`,
    transition: "0.1s all",
    ":focus": {
      boxShadow: (props) => `inset 0 0 0 1px ${props.theme.colors.blue[2]}`,
      outline: "none",
    },
  },
  fontWeight,
  borders,
  borderColor,
  borderRadius,
  buttonStyle,
  themed("Button")
);

Button.propTypes = {
  ...fontWeight.propTypes,
  ...borders.propTypes,
  ...borderColor.propTypes,
  ...borderRadius.propTypes,
  ...buttonStyle.propTypes,
};

Button.defaultProps = {
  as: "button",
  fontSize: "inherit",
  fontWeight: "bold",
  px: 3,
  py: 2,
  color: "white",
  bg: "blue",
  border: 0,
  borderRadius: 4,
};

export const Image = styled(Box)(
  {
    maxWidth: "100%",
    height: "auto",
  },
  height,
  borderRadius,
  themed("Image")
);

Image.propTypes = {
  ...height.propTypes,
  ...borderRadius.propTypes,
};

Image.defaultProps = {
  as: "img",
  m: 0,
};

const cards = variant({ key: "cards" });

export const Card = styled(Box)(
  borders,
  borderColor,
  borderRadius,
  boxShadow,
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  backgroundRepeat,
  opacity,
  cards,
  themed("Card")
);

Card.propTypes = {
  ...borders.propTypes,
  ...borderColor.propTypes,
  ...borderRadius.propTypes,
  ...boxShadow.propTypes,
  ...backgroundImage.propTypes,
  ...backgroundSize.propTypes,
  ...backgroundPosition.propTypes,
  ...backgroundRepeat.propTypes,
  ...opacity.propTypes,
  ...cards.propTypes,
};

Card.defaultProps = {
  variant: "basic",
  p: [3, 5],
  mb: 4,
  mr: [2, 4],
};

export const CardContainer = (props) => <Box {...props} />;
CardContainer.defaultProps = {
  mx: [-2, -4],
  pl: [2, 4],
};

export const Container = (props) => <Box {...props} />;
Container.defaultProps = {
  px: [2, 4],
};

export const BoundContainer = (props) => <Box {...props} />;
BoundContainer.defaultProps = {
  maxWidth: "1450px",
  mx: "auto",
  width: "100%",
};

export const NumberText = styled(Text)`
  font-variant-numeric: tabular-nums;
`;

export const InlineBox = styled(Box)`
  display: inline-block;
`;

export const InlineText = styled(Text)`
  display: inline-block;
`;

export const InlineNumberText = styled(NumberText)`
  display: inline-block;
`;

export const InlineFlex = styled(Flex)`
  display: inline-flex;
`;

export const Input = styled.input`
  width: 100%;
  border-radius: 4px;
  padding: ${(props) => props.theme.space[2]}px
    ${(props) => props.theme.space[4]}px;
  border: 1px solid ${(props) => props.theme.colors.gray[0]};
  transition: 0.1s all;
  ${color}
  ::placeholder {
    color: ${(props) => props.theme.colors.gray[5]};
  }
  :hover {
    border-color: ${(props) => props.theme.colors.primary};
  }
  :focus {
    box-shadow: inset 0 0 0 1px ${(props) => props.theme.colors.blue[2]};
    outline: none;
  }
`;

export const Select = styled.select`
  border-radius: 4px;
  padding: ${(props) => props.theme.space[1]}px
    ${(props) => props.theme.space[6]}px ${(props) => props.theme.space[1]}px
    ${(props) => props.theme.space[2]}px;
  border: 1px solid ${(props) => props.theme.colors.gray[2]};
  transition: 0.1s all;
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='${(
      props
    ) =>
      encodeURIComponent(
        props.theme.colors.primary
      )}' d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z'/%3E%3C/svg%3E")
    no-repeat right 0.5rem center;
  background-size: 20px 20px;
  display: inline-block;
  appearance: none;

  ::placeholder {
    color: ${(props) => props.theme.colors.gray[5]};
  }
  :hover {
    border-color: ${(props) => props.theme.colors.primary};
  }
  :focus {
    box-shadow: inset 0 0 0 1px ${(props) => props.theme.colors.blue[2]};
    outline: none;
  }
`;

export const ButtonGroupButton = styled(Button)`
  flex: 1;
  :not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }
  :not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const ButtonGroup = styled(Flex)`
  width: 100%;
`;

export const ButtonRadio = ({ options, value, onChange, ...other }) => {
  return (
    <ButtonGroup {...other}>
      {options.map((option) => {
        const active = value === option.value;
        return (
          <ButtonGroupButton
            variant={active ? "btn-radio-active" : "btn-radio"}
            key={option.value}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </ButtonGroupButton>
        );
      })}
    </ButtonGroup>
  );
};

export const IconButton = styled(Button)`
  width: 1.6em;
  height: 1.6em;
  padding: 0;
  line-height: 0;
  ${(props) => (props.circle ? `border-radius: 1.6em` : undefined)}
`;

export const CardDescription = styled(Text)`
  color: ${(props) => props.theme.colors.gray[6]};
  font-size: ${(props) => props.theme.fontSizes[1]}px;
`;
CardDescription.defaultProps = {
  mb: "4",
  lineHeight: "1.5",
};

export const CardHelpText = styled(Text)`
  color: ${(props) => props.theme.colors.gray[6]};
  font-size: ${(props) => props.theme.fontSizes[1]}px;
  margin-top: ${(props) => props.theme.space[5]}px;
`;

const fill = style({
  prop: "fill",
  key: "colors",
});
const stroke = style({
  prop: "stroke",
  key: "colors",
});
export const SvgText = styled.text`
  pointer-events: none;
  font-variant-numeric: tabular-nums;
  ${fill};
  ${fontSize};
  ${fontFamily};
  ${fontWeight};
  ${letterSpacing};
`;
SvgText.defaultProps = {
  dominantBaseline: "hanging",
};

export const SvgRectButton = styled.rect`
  ${color}
  ${fill}
  ${stroke}
  cursor: pointer;
  :hover {
    fill: ${(props) => {
      const fill = ssget(props.theme.colors, props.fill);
      return darken(0.08, fill);
    }};
  }
`;

export const SvgTextButton = ({
  left,
  top,
  width,
  height,
  children,
  ...other
}) => {
  return (
    <Group left={left} top={top}>
      <SvgRectButton width={width} height={height} fill="white" />
      <SvgText verticalAnchor="start">{children}</SvgText>
    </Group>
  );
};

export const ValueColumnHeader = styled(Text)`
  color: ${(props) => props.theme.colors.gray[5]};
  /* text-transform: uppercase; */
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  font-weight: 400;
  text-align: center;
  line-height: 1;
  vertical-align: bottom;
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
      user-select: none;
      transition: 0.1s color;
      :hover {
        color: ${props.theme.colors.gray[8]};
      }
    `}
`;
ValueColumnHeader.defaultProps = { ...Text.defaultProps, as: "th" };

export const StyledTable = styled.table`
  font-size: ${(props) => props.theme.fontSizes[1]}px;
  table-layout: ${(props) => (props.fixed ? "fixed" : null)};
`;

export const StatRow = styled.tr``;

export const EllipsisLink = styled(Link)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Th = styled.th`
  text-align: left;
  font-variant-numeric: tabular-nums;
  color: ${(props) => props.theme.colors.panelHeading};
  ${space};
  ${fontWeight};
`;

export const Td = styled.td`
  vertical-align: ${(props) => props.valign || "middle"};
  font-weight: ${(props) => props.fontWeight};
  width: ${(props) => (props.width ? `${props.width}px` : null)};
  ${space};
`;
Td.defaultProps = { py: 1 };

export const ChartTd = styled(Td)`
  width: ${(props) => (props.width ? `${props.width}px` : "250px")};
`;

export const NumberTd = styled(Td)`
  text-align: ${(props) => props.textAlign || "right"};
  font-variant-numeric: tabular-nums;
`;

export const NumberTh = styled.th`
  text-align: right;
  font-variant-numeric: tabular-nums;
  color: ${(props) => props.theme.colors.panelHeading};
  vertical-align: top;
  width: ${(props) => (props.width ? `${props.width}px` : null)};
  ${space};
`;

export const ValueList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin: 0;
`;

export const ValueListItem = styled.li``;
export const ValueListItemLabel = styled(Text).attrs(({ width }) => ({
  style: {
    width,
  },
}))`
  display: inline-block;
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  ${(props) =>
    props.color == null ? `color: ${props.theme.colors.gray[6]};` : null}
  vertical-align: top;
`;

export const Divider = styled(Box)`
  background-color: ${(props) => props.theme.colors.gray[1]};
  height: 1px;
`;

export const TooltipDiv = styled.div`
  z-index: 10000;
  max-width: ${(props) => props.maxWidth || `500px`};
`;

const Tooltip = React.forwardRef(({ children, onClose, ...other }, ref) => (
  <TooltipDiv ref={ref} {...other}>
    <Card variant="tooltip" p={4} style={{ position: "relative" }}>
      <IconButton
        key={"close"}
        variant="basic-light"
        circle
        fontSize={2}
        ml={2}
        mr={-3}
        mt={-1}
        onClick={onClose}
        aria-label="Close dialog"
        // style={{ position: "absolute", right: 0, top: 0 }}
        style={{ float: "right" }}
      >
        <IconClose />
      </IconButton>
      {children}
    </Card>
  </TooltipDiv>
));

export const InfoTooltip = ({ children, ...other }) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <TooltipTrigger
      placement="left"
      trigger="click"
      tooltip={({ tooltipRef, getTooltipProps }) => (
        <Tooltip
          {...getTooltipProps({
            ref: tooltipRef,
          })}
          onClose={() => setExpanded(false)}
        >
          <Text fontSize={1}>{children}</Text>
        </Tooltip>
      )}
      tooltipShown={expanded}
      onVisibilityChange={setExpanded}
    >
      {({ getTriggerProps, triggerRef }) => (
        <span
          {...getTriggerProps({
            ref: triggerRef,
            className: "trigger",
          })}
          style={{ lineHeight: 0 }}
        >
          <IconButton
            aria-label={expanded ? "Show teammates" : "Hide teammates"}
            ml={2}
            circle
            variant="basic-light"
            expanded={expanded}
            color="gray.5"
          >
            <IconInfo width="1.4em" />
          </IconButton>
        </span>
      )}
    </TooltipTrigger>
  );
};
