import React from 'react';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import PlayerPage from './pages/PlayerPage';
import { Switch, Route } from 'react-router-dom';
import { withTracker } from './ga';
import { AppProvider } from './app-context';

export default function App() {
  return (
    <AppProvider>
      <Switch>
        <Route path="/p/:playerId" component={withTracker(PlayerPage)} />
        <Route path="/about" component={withTracker(AboutPage)} />
        <Route component={withTracker(HomePage)} />
      </Switch>
    </AppProvider>
  );
}
