import React from 'react';
import { SvgText } from '../core';
import { seasonFormat } from '../../format';
import styled from 'styled-components/macro';
import { Group } from '@vx/group';
import { sortedExtentsBy } from '../../util';

const SeasonLine = styled.line`
  stroke: ${props =>
    props.dark ? props.theme.colors.gray[5] : props.theme.colors.white};
  stroke-opacity: 0.15;
  pointer-events: none;
`;

const SeasonBoxRect = styled.rect`
  fill: ${props =>
    props.index % 2 === 0
      ? props.theme.colors.white
      : props.theme.colors.gray[0]};
  fill-opacity: 0.5;
`;

const SeasonMarkers = ({
  xScale,
  data,
  xKey,
  plotAreaHeight,
  lines,
  darkLines,
}) => {
  const seasonExtents = React.useMemo(
    () => sortedExtentsBy(data, 'season_id', xKey, true),
    [data, xKey]
  );

  return (
    <Group>
      {seasonExtents.map(([season, seasonMin, seasonMax], i) => {
        const xStart = xScale(seasonMin);
        const xEnd = xScale(seasonMax);
        const boxWidth = Math.max(1, xEnd - xStart);
        let label = seasonFormat(season);
        if (boxWidth < 35) {
          label = null;
        } else if (boxWidth < 55) {
          label = season;
        }
        return (
          <Group key={season} left={xStart}>
            {lines ? (
              <SeasonLine
                x1={boxWidth}
                x2={boxWidth}
                y1={0}
                y2={plotAreaHeight}
                dark={darkLines}
              />
            ) : (
              <SeasonBoxRect
                index={i}
                width={boxWidth}
                height={plotAreaHeight}
              />
            )}
            <SvgText
              dy={'-1.2em'}
              x={3}
              textAnchor="start"
              fontSize={0}
              fill="gray.6"
            >
              {label}
            </SvgText>
          </Group>
        );
      })}
    </Group>
  );
};

export default SeasonMarkers;
