import "styled-components/macro";
import React from "react";
import Helmet from "react-helmet";
import { Card, Container, Heading, Text, Flex } from "../components/core";
import Layout from "../components/Layout";
import { longTermFga } from "../config";

const AboutPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>About - Shotline: NBA Shooting Vis</title>
      </Helmet>
      <Container px={[2, 5]}>
        <Flex alignItems="center" justifyContent="center" mt={5} mb={5}>
          <Heading as="h1" fontSize={4}>
            About Shotline
          </Heading>
        </Flex>
        <Card variant="basic" mr={null} ml={null} mx={"auto"} maxWidth="700px">
          <Text>
            <p>
              After creating{" "}
              <a href="https://buckets.peterbeshai.com">Buckets</a> in late 2014
              and subsequently working at the Boston Celtics, I took a break
              from really thinking about basketball much (besides keeping up
              with the league drama via{" "}
              <a href="https://www.reddit.com/r/nba">r/nba</a>). When 2019
              rolled around, I became curious to see if there was any truth to
              whether or not a player gets better at shooting over their career.
              After doing a bit of exploratory data analysis in R, I thought
              it'd be a fun project to share some of the performance trends
              players go through as opposed to just FG% averages over standard
              windows.
            </p>
            <p>
              {`With a bit of experimentation, I settled on using ${longTermFga} shot windows
              to compute moving averages of shooting percentages. You can read a
              number like 52% as "the player hit ${
                (52 * longTermFga) / 100
              } of his last ${longTermFga} shots."
              Every 2P% and 3P% stat you see on the site is based on this
              somewhat unusual average (so it may not match up with FG% you see
              elsewhere, which is typically based on a complete season or set of
              games).`}
            </p>
            <p>
              To understand whether or not a player actually gets better over
              time, we need some kind of baseline to compare their current
              performance against. On Shotline, the baseline is set after a
              player completes their first season in the NBA and has shot at
              least 200 times. This may sometimes feel a bit arbitrary, and I
              guess it is, but it feels reasonable to compare a player's first
              season's performance to their current to understand whether they
              have improved or not. The graphs are set up to allow you to
              compare their current performance against any other point in time
              too if the baseline is not sufficiently interesting to you.
            </p>
            <p>
              Hope you enjoy the site! If you have any questions or comments,
              feel free to reach out to me on Twitter{" "}
              <a href="https://twitter.com/pbesh">@pbesh</a>.
            </p>
          </Text>
          <Text fontWeight="700">— Peter Beshai</Text>
        </Card>
      </Container>
    </Layout>
  );
};

export default AboutPage;
