import { createGlobalStyle } from "styled-components/macro";
import { rgba, darken } from "polished";

// from open-color
const openColor = {
  black: "#000000",
  white: "#ffffff",
  gray: [
    "#f8f9fa",
    "#f1f3f5",
    "#e9ecef",
    "#dee2e6",
    "#ced4da",
    "#adb5bd",
    "#868e96",
    "#646e78",
    "#495057",
    "#343a40",
    "#212529",
  ],
  red: [
    "#fff5f5",
    "#ffe3e3",
    "#ffc9c9",
    "#ffa8a8",
    "#ff8787",
    "#ff6b6b",
    "#fa5252",
    "#f03e3e",
    "#e03131",
    "#c92a2a",
  ],
  pink: [
    "#fff0f6",
    "#ffdeeb",
    "#fcc2d7",
    "#faa2c1",
    "#f783ac",
    "#f06595",
    "#e64980",
    "#d6336c",
    "#c2255c",
    "#a61e4d",
  ],
  grape: [
    "#f8f0fc",
    "#f3d9fa",
    "#eebefa",
    "#e599f7",
    "#da77f2",
    "#cc5de8",
    "#be4bdb",
    "#ae3ec9",
    "#9c36b5",
    "#862e9c",
  ],
  violet: [
    "#f3f0ff",
    "#e5dbff",
    "#d0bfff",
    "#b197fc",
    "#9775fa",
    "#845ef7",
    "#7950f2",
    "#7048e8",
    "#6741d9",
    "#5f3dc4",
  ],
  indigo: [
    "#edf2ff",
    "#dbe4ff",
    "#bac8ff",
    "#91a7ff",
    "#748ffc",
    "#5c7cfa",
    "#4c6ef5",
    "#4263eb",
    "#3b5bdb",
    "#364fc7",
  ],
  blue: [
    "#e7f5ff",
    "#d0ebff",
    "#a5d8ff",
    "#74c0fc",
    "#4dabf7",
    "#339af0",
    "#228be6",
    "#1c7ed6",
    "#1971c2",
    "#1864ab",
  ],
  cyan: [
    "#e3fafc",
    "#c5f6fa",
    "#99e9f2",
    "#66d9e8",
    "#3bc9db",
    "#22b8cf",
    "#15aabf",
    "#1098ad",
    "#0c8599",
    "#0b7285",
  ],

  teal: [
    "#e6fcf5",
    "#c3fae8",
    "#96f2d7",
    "#63e6be",
    "#38d9a9",
    "#20c997",
    "#12b886",
    "#0ca678",
    "#099268",
    "#087f5b",
  ],
  green: [
    "#ebfbee",
    "#d3f9d8",
    "#b2f2bb",
    "#8ce99a",
    "#69db7c",
    "#51cf66",
    "#40c057",
    "#37b24d",
    "#2f9e44",
    "#2b8a3e",
  ],
  lime: [
    "#f4fce3",
    "#e9fac8",
    "#d8f5a2",
    "#c0eb75",
    "#a9e34b",
    "#94d82d",
    "#82c91e",
    "#74b816",
    "#66a80f",
    "#5c940d",
  ],
  yellow: [
    "#fff9db",
    "#fff3bf",
    "#ffec99",
    "#ffe066",
    "#ffd43b",
    "#fcc419",
    "#fab005",
    "#f59f00",
    "#f08c00",
    "#e67700",
  ],
  orange: [
    "#fff4e6",
    "#ffe8cc",
    "#ffd8a8",
    "#ffc078",
    "#ffa94d",
    "#ff922b",
    "#fd7e14",
    "#f76707",
    "#e8590c",
    "#d9480f",
  ],
};

const colors = {
  ...openColor,
  primary: openColor.indigo[7],
  link: openColor.indigo[7],
  linkHover: openColor.indigo[6],
  bodyText: openColor.gray[10],
  mainBackground: openColor.gray[0],
  panelHeading: openColor.gray[7],
  "2ptFg": openColor.violet[5],
  "2ptBg": openColor.violet[0],
  "3ptFg": openColor.cyan[5],
  "3ptBg": openColor.cyan[0],
  chart: {
    series5: [
      openColor.indigo[4],
      openColor.pink[4],
      openColor.yellow[5],
      openColor.teal[4],
      openColor.grape[4],
      // openColor.orange[4],
      // openColor.green[4], // too close to teal
      // openColor.lime[4],
      // openColor.blue[4],
      // openColor.cyan[4], // too close to teal
      // openColor.violet[4], // too close to indigo, but it's nice
      // openColor.red[4], // too close to pink
    ],
  },
};

const space = [0, 4, 8, 12, 16, 24, 32, 48, 64, 128, 256];
const shadows = {
  xs: `0 1px 2px ${rgba(colors.gray[8], 0.09)}`,
  small: `0 1px 4px ${rgba(colors.gray[8], 0.09)}`,
  medium: `0 2px 8px ${rgba(colors.gray[8], 0.09)}`,
  large: `0 2px 24px ${rgba(colors.gray[8], 0.09)}`,
};

export const theme = {
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors,
  space,
  fonts: {
    sans:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    mono: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
  },
  shadows,

  links: {
    basic: {
      color: "inherit",
      ":hover": {
        color: "inherit",
        textDecoration: "none",
      },
    },
  },
  buttons: {
    primary: {
      color: colors.white,
      backgroundColor: colors.primary,
      ":hover": {
        backgroundColor: darken(0.08, colors.primary),
      },
      ":active": {
        backgroundColor: darken(0.12, colors.primary),
      },
    },
    basic: {
      color: colors.bodyText,
      backgroundColor: colors.white,
      ":hover": {
        backgroundColor: colors.gray[1],
      },
      ":active": {
        backgroundColor: colors.gray[2],
      },
    },
    "basic-light": {
      color: colors.gray[5],
      backgroundColor: "transparent",
      ":hover": {
        color: colors.gray[6],
        backgroundColor: colors.gray[1],
      },
      ":active": {
        color: colors.gray[6],
        backgroundColor: colors.gray[2],
      },
    },
    "btn-radio": {
      color: colors.gray[7],
      backgroundColor: colors.gray[1],

      boxShadow: `inset 0 0 0 1px ${colors.gray[2]}`,

      ":hover": {
        backgroundColor: colors.gray[1],
      },
      ":active": {
        backgroundColor: colors.gray[2],
      },
    },
    "btn-radio-active": {
      color: colors.primary,
      backgroundColor: "transparent",
      boxShadow: `inset 0 0 0 1px ${rgba(colors.primary, 0.75)}`,
      // border: "1px solid red",
      zIndex: 1,
      ":hover": {
        backgroundColor: colors.gray[1],
      },
      ":active": {
        backgroundColor: colors.gray[2],
      },
    },
    gray: {
      color: colors.gray[7],
      backgroundColor: colors.gray[2],
      ":hover": {
        backgroundColor: colors.gray[3],
      },
      ":active": {
        backgroundColor: colors.gray[4],
      },
    },
    "input-btn": {
      color: colors.gray[5],
      backgroundColor: colors.white,
      ":hover": {
        color: colors.primary,
      },
    },
    outline: {
      color: colors.primary,
      backgroundColor: "transparent",
      boxShadow: "inset 0 0 0 1px",
      ":hover": {
        color: colors.white,
        backgroundColor: colors.primary,
        boxShadow: `inset 0 0 0 1px ${colors.primary}`,
      },
      ":active": {
        backgroundColor: darken(0.04, colors.primary),
        boxShadow: `inset 0 0 0 1px ${darken(0.04, colors.primary)}`,
      },
    },
    "gray-outline": {
      color: colors.gray[6],
      backgroundColor: "transparent",
      boxShadow: `inset 0 0 0 1px ${colors.gray[5]}`,
      ":hover": {
        // color: colors.white,
        color: colors.gray[7],
        backgroundColor: colors.gray[2],
        // boxShadow: `inset 0 0 0 1px ${colors.primary}`,
      },
      ":active": {
        color: colors.gray[7],

        backgroundColor: colors.gray[3],
        // boxShadow: `inset 0 0 0 1px ${darken(0.04, colors.primary)}`,
      },
    },
    "small-link": {
      color: colors.link,
      backgroundColor: "transparent",
      padding: 0,
      ":hover": {
        backgroundColor: colors.gray[1],
      },
      ":active": {
        backgroundColor: colors.gray[2],
      },
    },
  },

  cards: {
    basic: {
      backgroundColor: colors.white,
      color: colors.bodyText,
      boxShadow: shadows.xs,
      borderRadius: "8px",
    },
    tooltip: {
      backgroundColor: colors.gray[0],
      color: colors.bodyText,
      boxShadow: shadows.medium,
      borderRadius: "8px",
    },
  },
};

export default theme;

export const GlobalStyle = createGlobalStyle`
/*!
 * Bootstrap Reboot v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: ${theme.colors.link};
  text-decoration: none;
  background-color: transparent;
  transition: .1s all;
}

a:hover {
  color: ${theme.colors.linkHover};
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}
/*# sourceMappingURL=bootstrap-reboot.css.map */

html,
body,
#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ${theme.fonts.sans};
  line-height: 1.7;
  font-size: ${theme.fontSizes[2]}px;
  background-color: ${colors.mainBackground};
  color: ${colors.bodyText};
}

* {
  box-sizing: border-box;
}
button {
  border: none;
}
`;

/*

button, input
input[type="button" i], input[type="submit" i], input[type="reset" i], input[type="file" i]::-webkit-file-upload-button, button {
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border-style: solid;
  border-width: 1px;
  padding: 1px 7px 2px;
}
user agent stylesheet
input[type="button" i], input[type="submit" i], input[type="reset" i], input[type="file" i]::-webkit-file-upload-button, button {
  align-items: flex-start;
  text-align: center;
  cursor: default;
  color: buttontext;
  background-color: buttonface;
  box-sizing: border-box;
  padding: 2px 6px 3px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonface;
  border-image: initial;
}
user agent stylesheet
*/
