import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Box } from './components/core';

const BOOTSTRAP_QUERY = gql`
  query BootstrapQuery {
    bootstrap {
      lastUpdateDate
      latestSeasonId
    }
  }
`;

export const AppContext = React.createContext();
export function AppProvider({ children }) {
  return (
    <Query query={BOOTSTRAP_QUERY}>
      {({ loading, error, data }) => {
        if (loading) {
          return null;
        }
        if (error) {
          return <Box>Error</Box>;
        }

        const contextValue = {
          lastUpdateDate: data.bootstrap.lastUpdateDate,
          latestSeasonId: data.bootstrap.latestSeasonId,
        };
        return (
          <AppContext.Provider value={contextValue}>
            {children}
          </AppContext.Provider>
        );
      }}
    </Query>
  );
}

export function useLatestSeasonId() {
  const { latestSeasonId } = React.useContext(AppContext);
  return latestSeasonId;
}
