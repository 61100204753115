import "styled-components/macro";
import React from "react";
import { Box, Heading, Card } from "./core";
import ExpandControl from "./ExpandControl";
import PlayerDeltaStatTable, {
  PlayerDeltaStatFlatTable,
  resolveStatKeys,
} from "./PlayerDeltaStatTable";
import { shortTermFga, longTermFga } from "../config";
import ShotPtsPanelHeader from "./ShotPtsPanelHeader";

export const deltaMessages = {
  short_term: `${shortTermFga} Shot Window`,
  long_term: `${longTermFga} Shot Window`,
  season: `Current vs. Previous Season`,
  baseline: `Last ${longTermFga} vs. Career Baseline`,
};

const PlayerStatPanel = ({
  players,
  shotPts,
  deltaType,
  noMaxHeight,
  recentFilter,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const expandable = players.length > 20;
  const { rankKey } = resolveStatKeys(deltaType, shotPts);
  const unrankedPlayers = React.useMemo(
    () => players.filter((d) => d.derivedPlayerStats[rankKey] == null),
    [players, rankKey]
  );
  const isExpanded = expandable ? expanded : true;

  return (
    <Card
      variant="basic"
      height={noMaxHeight ? undefined : "460px"}
      overflow="auto"
      mr={0}
      mb={0}
    >
      <ShotPtsPanelHeader
        shotPts={shotPts}
        control={
          expandable && (
            <ExpandControl
              variant="gray"
              aria-label={expanded ? "Expand list" : "Contract list"}
              expanded={expanded}
              onChange={setExpanded}
            />
          )
        }
      >
        {deltaMessages[deltaType]}
      </ShotPtsPanelHeader>
      <PlayerDeltaStatTable
        players={players}
        shotPts={shotPts}
        deltaType={deltaType}
        expanded={isExpanded}
        recentFilter={recentFilter}
      />
      {isExpanded && unrankedPlayers.length > 0 && (
        <Box backgroundColor="gray.0" p={3} m={-3} mt={5}>
          <Heading
            as="h6"
            textAlign="center"
            color="gray.6"
            fontSize={1}
            mb={1}
            fontWeight="700"
          >
            Not enough shots
          </Heading>
          <PlayerDeltaStatFlatTable
            players={unrankedPlayers}
            shotPts={shotPts}
            deltaType={deltaType}
            hideDelta
          />
        </Box>
      )}
    </Card>
  );
};

export default PlayerStatPanel;
