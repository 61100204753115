import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

// Create the keyframes
const bounce = keyframes`
  from {
    transform: translate3d(0, 0, 0) scaleY(1.02);
  }
  90% {
    
  }
  100% {
    transform: translate3d(0, 100%, 0) scaleY(0.9); 
  }
`;
const Bounce = styled.div`
  display: inline-block;
  animation: ${bounce} 400ms ease-in-out infinite;
  animation-direction: alternate;
  will-change: transform;
  color: ${props => props.theme.colors.primary};
`;

export default function LoadingSpinner() {
  return (
    <Bounce>
      <svg
        viewBox="0 0 16 16"
        preserveAspectRatio="none"
        width="1.5em"
        fill="currentColor"
        stroke="none"
      >
        <circle cx="8" cy="8" r="7" />
      </svg>
    </Bounce>
  );
}
