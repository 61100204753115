import React from 'react';
import {
  Box,
  Text,
  SvgText,
  InlineText,
  InlineBox,
  ValueList,
  ValueListItem,
  ValueListItemLabel,
} from './core';
import { interpolatePath } from 'd3-interpolate-path';
import {
  area as d3_area,
  stack as d3_stack,
  stackOrderNone,
  stackOffsetNone,
} from 'd3-shape';
import { scaleLinear, scaleBand } from 'd3-scale';
import { Spring } from 'react-spring/renderprops';
import { pctFormat0Symbol, seasonFormat } from '../format';
import styled from 'styled-components/macro';
import { Group } from '@vx/group';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { Bar } from '@vx/shape';
import { localPoint } from '@vx/event';
import { colorScales } from '../scales';
import { sampleDataset } from '../util';
import SeasonMarkers from './charts/SeasonMarkers';
import TeamMarkers from './charts/TeamMarkers';
import { movingAverageWindowBins, bins, binsShort } from '../config';
import { numTicksForHeight, numTicksForWidth, chartCss } from './charts/charts';

const TooltipLine = styled.line`
  stroke: #fff; //${props => props.theme.colors.gray[6]};
  stroke-opacity: 0.5;
  pointer-events: none;
`;

const TooltipCircle = styled.circle`
  stroke: white;
  fill: ${props => props.fill};
  pointer-events: none;
`;

const TooltipBox = styled(Box).attrs(({ left, top, bottom, right }) => ({
  style: {
    left,
    top,
    bottom,
    right,
  },
}))`
  position: absolute;
  white-space: nowrap;
  pointer-events: none;
  background: #fff;
  color: ${props => props.theme.colors.bodyText};
  font-size: ${props => props.theme.fontSizes[0]}px;
  padding: ${props => props.theme.space[2]}px ${props => props.theme.space[3]}px;
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadows.small};
  z-index: 1000;
`;

const BinBarChart = ({ data, width, height }) => {
  const margin = { top: 0, left: 0, right: 0, bottom: 32 };
  const plotAreaWidth = width - margin.left - margin.right;
  const plotAreaHeight = height - margin.top - margin.bottom;

  const yMax = Math.max(
    0.4, // use 0.4 as the standard unless something is bigger in the data
    data.shot_bin_1_pct_mavg,
    data.shot_bin_2_pct_mavg,
    data.shot_bin_3_pct_mavg,
    data.shot_bin_4_pct_mavg,
    data.shot_bin_5_pct_mavg
  );
  // scales
  const xScale = scaleBand()
    .domain(bins)
    .range([0, plotAreaWidth])
    .padding(0.04);

  const yScale = scaleLinear()
    .domain([0, yMax])
    .range([0, plotAreaHeight])
    .nice();

  return (
    <svg width={width} height={height} style={{ position: 'relative' }}>
      <Group top={margin.top} left={margin.left}>
        {bins.map((bin, i) => {
          const value = data[`shot_bin_${i + 1}_pct_mavg`];
          const barHeight = yScale(value);
          const x = xScale(bin);
          return (
            <Group key={i} left={x}>
              <rect
                key={i}
                y={plotAreaHeight - barHeight}
                height={barHeight}
                width={xScale.bandwidth()}
                fill={colorScales.shot_bin(i)}
              />
              <SvgText
                dy={'1.85em'}
                fontSize={'9px'}
                fill="gray.6"
                y={plotAreaHeight}
                x={xScale.bandwidth() / 2}
                textAnchor={'middle'}
              >
                {binsShort[i]}
              </SvgText>
              <SvgText
                dy={'0.3em'}
                fontSize={'10px'}
                fill="gray.8"
                y={plotAreaHeight}
                x={xScale.bandwidth() / 2}
                textAnchor={'middle'}
              >
                {pctFormat0Symbol(value)}
              </SvgText>
            </Group>
          );
        })}
      </Group>
    </svg>
  );
};

const Tooltip = ({
  player,
  xScale,
  shotPts,
  plotAreaHeight,
  plotAreaWidth,
  data,
}) => {
  const numShots = data.player_shot_index_overall;
  const season = data.season_id;
  const team = data.team_abbr;
  const top = plotAreaHeight * 0.3;
  const xMargin = 20;
  let left;
  let right;
  const x = xScale(data.player_shot_index_overall);
  const barChartWidth = 150;
  const barChartHeight = 85;
  if (x > barChartWidth + 10) {
    right = plotAreaWidth - x + xMargin;
  } else {
    left = x + xMargin;
  }

  const minShots = Math.max(1, numShots - movingAverageWindowBins);
  const maxShots = numShots;

  return (
    <TooltipBox left={left} right={right} top={top}>
      <Box my={0}>
        <BinBarChart
          data={data}
          width={barChartWidth}
          height={barChartHeight}
        />
      </Box>
      <ValueList>
        <ValueListItem>
          <ValueListItemLabel width={'4em'}>Shots</ValueListItemLabel>
          {minShots === maxShots ? '' : `${minShots.toLocaleString()} – `}
          {maxShots.toLocaleString()}
        </ValueListItem>
        <ValueListItem>
          <ValueListItemLabel width={'4em'}>Season</ValueListItemLabel>
          {seasonFormat(season)}
        </ValueListItem>
        <ValueListItem>
          <ValueListItemLabel width={'4em'}>Team</ValueListItemLabel>
          {team}
        </ValueListItem>
      </ValueList>
    </TooltipBox>
  );
};

const CanvasStackedArea = ({
  t,
  width,
  height,
  margin,
  xScale,
  yScale,
  series,
  plotAreaWidth,
}) => {
  const seriesD = React.useMemo(() => {
    // path d generator for an area
    const makeArea = d3_area()
      .x((d, j) => xScale(d.data.player_shot_index_overall))
      // .y1(d => yScale(d[1]))
      .y1(d => yScale(1))
      .y0(d => yScale(d[0]));
    // .curve(curveCatmullRom);
    const seriesD = series.map(oneSeries =>
      makeArea(sampleDataset(oneSeries, Math.ceil(width / 3)))
    );
    return seriesD;
  }, [series, width]); //, xScale, yScale]);
  const currSeriesD = React.useRef(seriesD);

  const pathInterpolators = React.useMemo(() => {
    return seriesD.map((oneSeriesD, i) => {
      const interpolator = interpolatePath(
        currSeriesD.current[i],
        oneSeriesD,
        (a, b) => a.x === b.x && (a.x === 0 || a.x === plotAreaWidth)
      );
      // const interpolator = interpolateString(
      //   currSeriesD.current[i],
      //   oneSeriesD
      // );

      return t => {
        const newD = interpolator(t);
        // currSeriesD.current[i] = newD;
        return newD;
      };
    });
  }, [seriesD, plotAreaWidth]);

  React.useEffect(() => {
    for (let i = 0; i < currSeriesD.current.length; ++i) {
      currSeriesD.current[i] = pathInterpolators[i](t);
    }
  });

  const canvasRef = React.useRef(null);
  React.useEffect(() => {
    // setup HiDPI display
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = width * window.devicePixelRatio;
    canvas.height = height * window.devicePixelRatio;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    ctx.scale(window.devicePixelRatio, window.devicePixelRatio);

    // account for margin
    ctx.translate(margin.left, margin.top);
  }, [width, height, margin.left, margin.top]);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(-width, -height, width * 2, height * 2);

    for (let i = 0; i < currSeriesD.current.length; ++i) {
      ctx.fillStyle = colorScales.shot_bin(i);
      ctx.fill(new Path2D(currSeriesD.current[i]));
    }
  });

  return <canvas width={width} height={height} ref={canvasRef} />;
};

export const PlayerStackedShotBinsLegend = props => {
  return (
    <Text {...props} lineHeight={1.2}>
      {bins.map((bin, i) => {
        return (
          <InlineText key={i} fontSize={0} mr={4} whiteSpace="nowrap">
            <InlineBox
              width={10}
              height={10}
              mr={1}
              borderRadius={10}
              bg={colorScales.shot_bin(i)}
            />
            {bin}
          </InlineText>
        );
      })}
    </Text>
  );
};

const PlayerStackedShotBins = ({
  player,
  width = 700,
  height = 300,
  ...other
}) => {
  const dataPoints = player.binShots;
  const xMax = dataPoints[dataPoints.length - 1].player_shot_index_overall;

  // bounds
  const margin = {
    top: 34,
    left: 34,
    right: 0,
    bottom: 43,
  };
  const plotAreaWidth = width - margin.left - margin.right;
  const plotAreaHeight = height - margin.top - margin.bottom;

  // scales
  const xScale = scaleLinear()
    .domain([1, xMax])
    .range([0, plotAreaWidth])
    .clamp(true);
  const yScale = scaleLinear()
    .domain([0, 1])
    .range([plotAreaHeight, 0])
    .clamp(true)
    .nice();

  const series = React.useMemo(() => {
    const makeStack = d3_stack()
      .keys([
        'shot_bin_1_pct_mavg',
        'shot_bin_2_pct_mavg',
        'shot_bin_3_pct_mavg',
        'shot_bin_4_pct_mavg',
        'shot_bin_5_pct_mavg',
      ])
      .order(stackOrderNone)
      .offset(stackOffsetNone);
    return makeStack(dataPoints);
  }, [dataPoints]);

  const lastSeries = React.useRef(series);
  React.useEffect(() => {
    lastSeries.current = series;
  }, [series]);

  // tooltips
  const [tooltipX, setTooltipX] = React.useState(null);

  // callback for mousemove to set a tooltip
  const handleMouseMove = React.useCallback(
    event => {
      const coords = localPoint(event.target.ownerSVGElement, event);
      const plotX = coords.x - margin.left;
      const newX = Math.round(xScale.invert(plotX));
      if (tooltipX !== newX) {
        setTooltipX(newX);
      }
    },
    [xScale, margin.left, tooltipX]
  );

  const tooltipData = React.useMemo(() => {
    // const tooltipX = 100;
    if (tooltipX == null) {
      return null;
    }
    let distance = Infinity;
    let result = undefined;
    for (const d of dataPoints) {
      const newDist = Math.abs(d.player_shot_index_overall - tooltipX);
      if (newDist < distance) {
        distance = newDist;
        result = d;
      } else {
        break;
      }
    }

    return result;
  }, [dataPoints, tooltipX]);

  return (
    <Box css={chartCss} {...other}>
      <Spring
        from={{ t: 0 }}
        reset={lastSeries.current !== series}
        to={{ t: 1 }}
      >
        {({ t }) => (
          <Box style={{ position: 'absolute', top: 0, left: 0 }}>
            <CanvasStackedArea
              t={t}
              margin={margin}
              series={series}
              xScale={xScale}
              yScale={yScale}
              width={width}
              height={height}
              plotAreaWidth={plotAreaWidth}
            />
          </Box>
        )}
      </Spring>

      <svg width={width} height={height} style={{ position: 'relative' }}>
        <Group top={margin.top} left={margin.left}>
          <SeasonMarkers
            xScale={xScale}
            data={dataPoints}
            xKey={'player_shot_index_overall'}
            plotAreaHeight={plotAreaHeight}
            lines
          />
          <TeamMarkers
            xScale={xScale}
            data={dataPoints}
            xKey={'player_shot_index_overall'}
            plotAreaHeight={plotAreaHeight}
          />
          {/* Tooltip in graph */}
          {tooltipData && (
            <Group left={xScale(tooltipData.player_shot_index_overall)}>
              <TooltipLine y1={0} y2={plotAreaHeight} />

              <TooltipCircle
                cy={yScale(tooltipData.shot_bin_1_pct_mavg)}
                r={3.5}
                fill={colorScales.shot_bin(0)}
              />
              <TooltipCircle
                cy={yScale(
                  tooltipData.shot_bin_1_pct_mavg +
                    tooltipData.shot_bin_2_pct_mavg
                )}
                r={3.5}
                fill={colorScales.shot_bin(1)}
              />
              <TooltipCircle
                cy={yScale(
                  tooltipData.shot_bin_1_pct_mavg +
                    tooltipData.shot_bin_2_pct_mavg +
                    tooltipData.shot_bin_3_pct_mavg
                )}
                r={3.5}
                fill={colorScales.shot_bin(2)}
              />
              <TooltipCircle
                cy={yScale(
                  tooltipData.shot_bin_1_pct_mavg +
                    tooltipData.shot_bin_2_pct_mavg +
                    tooltipData.shot_bin_3_pct_mavg +
                    tooltipData.shot_bin_4_pct_mavg
                )}
                r={3.5}
                fill={colorScales.shot_bin(3)}
              />
              <TooltipCircle
                cy={yScale(
                  tooltipData.shot_bin_1_pct_mavg +
                    tooltipData.shot_bin_2_pct_mavg +
                    tooltipData.shot_bin_3_pct_mavg +
                    tooltipData.shot_bin_4_pct_mavg +
                    tooltipData.shot_bin_5_pct_mavg
                )}
                r={3.5}
                fill={colorScales.shot_bin(4)}
              />
            </Group>
          )}
        </Group>
        {/* Axes */}
        <Group left={margin.left}>
          <AxisLeft
            top={margin.top}
            left={0}
            scale={yScale}
            numTicks={numTicksForHeight(height)}
            stroke="#1b1a1e"
            tickStroke="#8e205f"
            hideZero
            hideTicks
            hideAxisLine
            tickLength={0}
            tickFormat={pctFormat0Symbol}
            tickComponent={({ formattedValue, ...tickProps }) => (
              <text {...tickProps}>{formattedValue}</text>
            )}
          />
          <AxisBottom
            top={height - margin.bottom}
            left={0}
            scale={xScale}
            numTicks={numTicksForWidth(width)}
            label="Shots"
            labelOffset={14}
          />
        </Group>

        {/* Interaction rect */}
        <Bar
          x={margin.left}
          y={margin.top}
          width={plotAreaWidth + 1}
          height={plotAreaHeight}
          fill="transparent"
          onMouseMove={handleMouseMove}
          onMouseLeave={event => setTooltipX(null)}
        />
      </svg>
      {/* Tooltip layer */}
      {tooltipData && (
        <Box
          mt={margin.top}
          ml={margin.left}
          mr={`${margin.right}px`}
          mb={margin.bottom}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: `${plotAreaWidth}px`,
            height: `${plotAreaHeight}px`,
            pointerEvents: 'none',
          }}
        >
          <Tooltip
            player={player}
            xScale={xScale}
            plotAreaWidth={plotAreaWidth}
            plotAreaHeight={plotAreaHeight}
            data={tooltipData}
          />
        </Box>
      )}
    </Box>
  );
};
PlayerStackedShotBins.whyDidYouRender = false; //{ logOnDifferentValues: true };
export default React.memo(PlayerStackedShotBins);
